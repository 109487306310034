import styles from './styles.module.css';
import { useEffect, useRef, useState } from 'react';
import { emailRegex } from '../../shared/constants';
import { fb } from '../../shared/service';
import { useHistory } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import ReCAPTCHA from 'react-google-recaptcha';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [pw, setPW] = useState('');
  const [valid, setValid] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValid(
      email && emailRegex.test(email) && pw && isVerified);
  }, [email, pw, isVerified]);
  //captcha///////////
  function handleVerify(value) {
    console.log('cap val/res', value);
    setIsVerified(true);
  }
  ////////////////////////
  const login = (event) => {
    event.preventDefault();
    setLoading(true);
    if (!isVerified) {
      alert('Please Verify');
      return;
    }

    if (valid) {
      setError('');
      fb
        .auth
        .signInWithEmailAndPassword(email, pw)
        .then(res => {
          if (!res.user) {
            setError("We're having trouble logging you in. Please try again.");
          }
          history.push('/Dashboard');
        })
        .catch(err => {
          if (
            err.code === 'auth/invalid-email' ||
            err.code === 'auth/wrong-password'
          ) {
            setError('Invalid credentials');
          } else if (err.code === 'auth/user-not-found') {
            setError('No account for this email');
          }// else if(err.code ==='app/storage-get'){
          // window.location.push('Dashboard');
          //   }•••••••••••
          else {
            setError('Something went wrong :(' + err.code);
          }
        });
    }
  };


  return (
    <div className={styles.outside}>
      <div className={styles.main}>
        {loading && <Loading />}
        <form onSubmit={login} className={styles.form}>
          <div className={styles.h1}>Login</div>

          <div className={styles.p1}>email</div>
          <input
            type='email'
            value={email}
            placeholder='your@email.com'
            onChange={e => setEmail(e.target.value)}
          />
          <div className={styles.p1}>password</div>
          <input
            type='password'
            value={pw}
            placeholder='password'
            onChange={e => setPW(e.target.value)}
          />


          <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={handleVerify} style={{ marginTop: "16px", marginBottom: "16px" }} />
          <button type='submit' disabled={!valid}>Login</button>
        </form>
        {error && <div className={`error-message ${styles.error}`}>{error}</div>}

      </div>
    </div>
  );
};