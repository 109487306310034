
import {useEffect, useState} from 'react';
import styles from './styles.module.css';
import { emailRegex } from '../../shared/constants';
import { fb } from '../../shared/service';
import { useHistory, useParams } from 'react-router-dom';
import { useSize } from '../../hooks/useSize';
import { NavBar } from '../NavBar';


export const Signup = () => {
    const {id} = useParams();
    const [email, setEmail] = useState('');
    const [pw, setPW] = useState('');
    const [verifyPW, setVerifyPW] = useState(null);
    const [valid, setValid] = useState(false);
    const history = useHistory();
    const [error, setError] =useState('');
    const {size} = useSize();
    useEffect(() => {
        setValid(
            email && emailRegex.test(email) && pw && verifyPW && pw === verifyPW);
    },[email, pw, verifyPW]);

    const signup = () => {
        if(valid) {
            fb
             .auth
             .createUserWithEmailAndPassword(email,pw)
             .then((userCredential) => {
                const user = userCredential.user
                
                console.log('Signup Successfull!');
             history.push(`/create-page-details/${id}`);
             })
             .catch((error) => {
                if (
                    error.code ==='auth/email-already-in-use'
                ) {
                    setError('Email Already in Use')
                }
                if(
                    error.code ==='auth/weak-password'
                ) {
                    setError('Weak Password! Your password must be at least 6 characters.')
                }
                console.log('Code =>',error.code, 'Message => ',error.message)
               /* const code = error.code;
                if(code == '(auth/email-aready-in-use)'){
                    console.log('%!email already in use!%');
                }*/
             })
            
        }
    };



    const PwMsg = () => {

        if(verifyPW !== null || undefined){
            if(verifyPW === pw){
                return(
                    <div className={styles.PwMsg}>
                        Passwords Match!
                        </div>
                )
            }
            if(verifyPW !== pw){
                return(
                    <div className={styles.PwMsgBad}>
                       Your Passwords Do Not Match :(
                    </div>
                )
            }

        }

       /* return(
            <div></div>
        )*/

        return null;
            
        

        

        
    }

    return (
        <div className={styles.outside}>
            <NavBar value={size}/>
    <div className={styles.main}>
        {id && 
        <div className={styles.p1}>
            Looks like you're new here! Continue with the signup process to start using your new card.
        </div>
        }
        <div className={styles.h1}>Sign Up</div>
        <div className={styles.p1}>email</div>
        <input 
        type ='email' 
        value = {email}
        placeholder='your@email.com'
        onChange = {e => setEmail(e.target.value)}
        />
        <div className={styles.p1}>password</div>
        <input 
        type ='password' 
        value = {pw}
        placeholder='password'
        onChange = {e => setPW(e.target.value)}
        />
        <div className={styles.p1}>confirm password</div>
        <input 
        type ='password' 
        value = {verifyPW}
        placeholder='password'
        onChange = {e => setVerifyPW(e.target.value)}
        />
        <PwMsg/>
        {error && <div className={styles.error}>{error} </div>}
        <button onClick={signup} disabled={!valid}>Sign Up</button>
        

        </div>
        </div>
    );
};