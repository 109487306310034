import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
//import ReactCrop from 'react-image-crop';
//import 'react-image-crop/dist/ReactCrop.css';
//import React, { useState, useRef } from 'react';

import Draggable from 'react-draggable';
//import React, { useState, useRef } from 'react';
import imageCompression from 'browser-image-compression';
import Cropper from 'cropperjs';
//import "cropperjs/dist/cropper.min.css";
import 'cropperjs/dist/cropper.css';
import './crop.css'
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
export const CropperD = (props) => {

  const imageElement = useRef(null);
  const [cropper, setCropper] = useState();
  const [croppedImg, setCroppedImg] = useState(null);
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 300,
    useWebWorker: false
  }

  async function getCroppedImage() {
    const canvas = cropper.getCroppedCanvas();
   // const roundedCanvas = getRoundedCanvas(canvas);
   console.log('this is running in CropperD   1')
    const dataURL = canvas.toDataURL();
    console.log('this is running in CropperD  2')
    const base64Data = await fetch(dataURL);
    console.log('this is running in CropperD  3')
    const blob = await base64Data.blob();
    console.log('this is running in CropperD  4')
  
    try {
      console.log('this is running in CropperD  5')
      
      console.log('this is running in CropperD  6')
     // console.log('compressed file', compressedFile);
    const compressedFile = await imageCompression(blob, options);
      setCroppedImg((URL.createObjectURL(compressedFile)));

     // const compressedFile = blob; //skipping image compression
     // setCroppedImg((URL.createObjectURL(blob)));

    } catch (error) {
      console.log(error);
    }
    // setCroppedImg(blob);
    // props.onCrop(dataURL);
  };
  useEffect(() => {
    if (croppedImg) {
      props.getCroppedImage(croppedImg);
      props.handleClose();
      console.log('croppedImg Changed')
    }

  }, [croppedImg]);

  const getRoundedCanvas = (sourceCanvas) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();
    return canvas;
  };

  const onImageLoad = (event) => {
    const imageElement = event.target;
    const cropper = new Cropper(imageElement, {
      aspectRatio: 1,
      viewMode: 1,
      ready: function () {
        // Do nothing
        // croppable = true
      },
    });
    setCropper(cropper);
  };

  return (
    <div className={styles.popBackground}>
      <div className={styles.cropPop}>
        <div className={styles.closeCont}> <Icon path={mdiClose} size={1} className={styles.closeSocialPop} onClick={props.handleClose} /></div>
        <div className={styles.titleS}>Crop Your Profile Pic:</div>

    
      
        <img
          ref={imageElement}
          style={{height: '200px'}}
          src={props.value}
          alt="Picture"
          onLoad={onImageLoad}
        />
    
     
     
        <button className={styles.cropBtn} type="button" onClick={getCroppedImage}>Set as Profile Pic</button>
     
      <div id="result"></div>
      </div>
    </div>
  );
};