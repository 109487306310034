
import { useEffect, useRef, useState } from 'react';

import styles from './styles.module.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fb } from '../../shared/service';
import { useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiClose, mdiDotsGrid, mdiSwapHorizontal, mdiTrashCan } from '@mdi/js';
import { urlRegex } from '../../shared';
import { AddBtnPic } from '../CreatePage/AddBtnPic';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { async } from '@firebase/util';
import ReactSwitch from 'react-switch';




export function Drag({ pageLinks, setPageLinks, pageId }) {

  const page = pageLinks;
  const [currentLink, setCurrentLink] = useState([]);
  const [characters, setCharacters] = useState(page);
  const [editOpen, setEditOpen] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [validLURL, setValidLURL] = useState(false);
  const hiddenBtnPicInput = useRef(null)
    ;
  useEffect(() => {
    setValidLURL(currentLink?.url && urlRegex.test(currentLink.url) && currentLink?.url.length <= 2000)
    //console.log('currentSocialLink url lenth', currentSocialLink?.url?.length);
  }, [currentLink?.url]);
  const [urlAdded, setUrlAdded] = useState(false);

  const addImgUrlToLinks = async (pageLinks) => {
    const storage = getStorage();
    const pageLinksWithURLs = await Promise.all(
      pageLinks?.map(async (link) => {
        if (link.StorageSrc) {
          try {
            const storageRef = ref(storage, link.StorageSrc);
            const downloadURL = await getDownloadURL(storageRef);

            console.log('drag download url from StorageSrc', downloadURL)
            return { ...link, imageURL: downloadURL };

          } catch (error) {
            console.error('error retrieving download URL');
            return link;
          }
        } else {
          return link;
        }
      })
    )
    console.log('page links with urls => ', pageLinksWithURLs)
    setCharacters(pageLinksWithURLs)
    setUrlAdded(true);
  }


  useEffect(() => {
    if (pageLinks !== characters) {
      console.log('page links from Drag', pageLinks)
      setCharacters(pageLinks);
    }
  }, [pageLinks])
  useEffect(() => {
    addImgUrlToLinks(characters);
  }, [])


  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCharacters(items);
    setClickedIndex(result.destination.index)
    console.log('result =>', result)
  }

  useEffect(() => {
    setPageLinks(characters);
  }, [characters])

  const deleteLink = (index) => {
    setCharacters(characters.filter((_, i) => i !== index));
  };
  ///////////////////////Edit button
  function toggleEdit(index) {
    setCurrentLink(characters[index])
    setClickedIndex(index);
    setEditOpen(!editOpen);
    //  setCurrentLink({ url: '', name: '', id: '' });
  }

  const addBtnPic = () => {
    hiddenBtnPicInput.current.click()
  };
  // / <input type="file" ref={hiddenBtnPicInput} style={{ display: 'none' }} />
  const [BtnImage, setBtnImage] = useState();

  const settingBtnImg = (e) => {
    setBtnImage(e);
  }

  const [pictureBool, setPictureBool] = useState();
  const [bodyBool, setBodyBool] = useState();
  const [btnBool, setBtnBool] = useState();

  const handlePictureBool = (val) => {
    setPictureBool(val);
  };
  const handleBodyBool = (val) => {
    setBodyBool(val);
  };
  const handleBtnBool = (val) => {
    setBtnBool(val);
  };

  const [taHeight, setTaHeight] = useState();
  function handleBody(event) {
    setCurrentLink(
      { ...currentLink, body: event.target.value });
    const h = event.target.scrollHeight;
    const rows = event.target.rows;
    const rowHeight = 15;
    const trows = Math.ceil(h / rowHeight) - 1;
    if (trows) {
      setTaHeight(trows);
    };
  }

  const EditLink = (index) => {
    console.log('Edit Link', currentLink)
    //setBtnImage(currentLink.imageURL);
    return (
      <div className={styles.popBackground}>
        <div className={styles.linkInputs}>
          <div className={styles.closeCont}>
            <div className={styles.leftBtnCont}>
              <button
                onClick={toggleEdit}
                className={styles.navBtn}>Back</button>
            </div>
            <div className={styles.rightBtnGroup}>
              <button
                onClick={() => {
                  var foundIndex = pageLinks.findIndex(x => x.id == currentLink.id);
                  setCurrentLink({ url: '', name: '', id: '' });
                  pageLinks[foundIndex] = currentLink;
                  toggleEdit();
                }}
                disabled={!currentLink.name || !currentLink.url || !validLURL}
                className={styles.submit}>Save Changes
              </button>
            </div>
          </div>

          <div className={styles.titleS}>Edit Link:</div>
          <div className={styles.inputContainer}>
            <div className={styles.row}>
              <div className={styles.pageNameTitle}>Picture</div>
              <ReactSwitch className={styles.smallSwitch} checked={pictureBool} value={pictureBool} onChange={handlePictureBool}
                onColor="#9a81ff"
                checkedIcon={false}
                uncheckedIcon={false}
                handleDiameter={16}
                height={16}
                width={36}
                boxShadow="var(--shd, 0 2px 2.2px rgba(0, 0, 0, 0.5))"
              />
            </div>



            {pictureBool &&
              <div className={styles.row}>
                <hr className={styles.hDivider} />
                <div className={styles.row2}>
                  <div className={styles.leftContImgEdit}>

                  </div>
                  <div className={styles.dragImgContEdit}>
                    {currentLink.imageURL &&
                      <img className={styles.dragImgEdit}
                        src={currentLink.imageURL}
                      />}
                    <img className={styles.gradImgEdit} src={BtnImage} />
                    <button className={styles.swapCont2} onClick={addBtnPic}>
                      <Icon path={mdiSwapHorizontal} size={1} className={styles.swap} onClick={addBtnPic} />
                    </button>
                    <input type="file"
                      accept='image/*'
                      onChange={(e) => {
                        settingBtnImg(e.target.files[0])
                        // setCurrentLink({ ...currentLink, imageUrl: e.target.files[0]})
                      }}
                      ref={hiddenBtnPicInput}
                      style={{ display: 'none' }} />
                    {BtnImage && <AddBtnPic currentLink={currentLink} setCurrentLink={setCurrentLink} image={BtnImage} setImage={setBtnImage} pageId={pageId} pageLinks={characters} setPageLinks={setPageLinks}
                    />}
                    <AddBtnPic currentLink={currentLink} setCurrentLink={setCurrentLink} image={BtnImage} setImage={setBtnImage} pageId={pageId} pageLinks={characters} setPageLinks={setPageLinks}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.row}>
              <div className={styles.pageNameTitle}>Title</div>
              <input
                className={styles.shortTxtInput}
                placeholder="Link Title *"
                value={currentLink.name}
                onChange={e => setCurrentLink(
                  { ...currentLink, name: e.target.value })}
              />
            </div>
          </div>


          <div className={styles.inputContainer}>
            <div className={styles.row}>
              <div className={styles.pageNameTitle}>Body</div>
              <ReactSwitch className={styles.smallSwitch} checked={bodyBool} value={bodyBool} onChange={handleBodyBool}
                onColor="#9a81ff"
                checkedIcon={false}
                uncheckedIcon={false}
                handleDiameter={16}
                height={16}
                width={36}
                boxShadow="var(--shd, 0 2px 2.2px rgba(0, 0, 0, 0.5))"
              />
            </div>
            {bodyBool &&
              <div className={styles.row}>
                <hr className={styles.hDivider} />
                <textarea
                  className={styles.inputBio}
                  value={currentLink.body}
                  placeholder={'Body'}
                  onChange={handleBody}
                  rows={taHeight}
                //  ref={textAreanRef} 
                />
              </div>
            }
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.row}>
              <div className={styles.pageNameTitle}>Link URL</div>

              <input
                className={styles.greyInput2}
                placeholder="Link Url *"
                value={currentLink.url}
                onChange={e => setCurrentLink({ ...currentLink, url: e.target.value })}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.row}>
              <div className={styles.pageNameTitle}>Call To Action Button</div>
              <ReactSwitch className={styles.smallSwitch} checked={btnBool} value={btnBool} onChange={handleBtnBool}
                onColor="#9a81ff"
                checkedIcon={false}
                uncheckedIcon={false}
                handleDiameter={16}
                height={16}
                width={36}
                boxShadow="var(--shd, 0 2px 2.2px rgba(0, 0, 0, 0.5))"
              />
            </div>

            {btnBool &&
              <div className={styles.row}>
                <hr className={styles.hDivider} />
                <div className={styles.pageNameTitle}>Text:</div>
                <input className={styles.shortTxtInput}
                  placeholder='"Call To Action"'
                />

              </div>
            }
          </div>


        </div>
      </div>
    )
  }


  useEffect(() => {
    // console.log('BTNIMAGE', BtnImage)
  }, [BtnImage, editOpen])

  // {StorageSrc && <img src={l.imageURL} className={styles.dragImg} />}
  return (
    <div className={styles.main}>
      {urlAdded !== false &&
        <DragDropContext onDragEnd={handleOnDragEnd} className={styles.dragdropcontext}>
          <Droppable droppableId='characters' className={styles.droppable}>
            {(provided) => (
              <div className={styles.char} {...provided.droppableProps} ref={provided.innerRef}>
                {characters?.map(({ id, name, StorageSrc, imageURL }, index, l) => {
                  const hasImg = l?.imageUrl && l?.imageUrl !== '' || undefined;
                  //console.log('l after map => ', l, 'h i', hasImg)
                  const linkStyles = hasImg ? styles.linkWithImg : styles.link
                  if (hasImg) {
                    return (
                      <div className={styles.imgBtnRow}>
                        <Draggable key={id} draggableId={id} index={index} className={styles.draggable} value={l}>
                          {(provided) => (
                            <div className={styles.btnOutside}>
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={linkStyles}>
                                <div className={styles.btnIconRow}>
                                  <Icon path={mdiTrashCan} size={1} className={styles.deleteIcoBtn} onClick={() => deleteLink(index)} />
                                  <Icon path={mdiDotsGrid} size={1} className={styles.dragIcoBtn}
                                    onClick={() => {

                                      toggleEdit(index);
                                    }}
                                  />
                                </div>
                                <div className={styles.linkImgCont}>
                                  {StorageSrc & <img src={l.imageURL} className={styles.linkImg} />}
                                </div>
                                <div className={styles.btnImgSubTxt}>
                                  {name}
                                </div>
                                {editOpen && index == clickedIndex && EditLink(clickedIndex)}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    )
                  } else {
                    return (
                      <Draggable key={id} draggableId={id} index={index} className={styles.draggable} value={l} >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={linkStyles}  >

                            {imageURL &&
                              <div className={styles.dragImgCont}>
                                <img src={imageURL} className={styles.dragImg} />
                              </div>
                            }
                            <div className={styles.linkRow}>
                              <Icon path={mdiTrashCan} height="20px" className={styles.deleteIco}
                                onClick={() => deleteLink(index)}
                              />

                              <div className={styles.pageName}>
                                {name}
                              </div>

                              <Icon path={mdiDotsGrid} height="20px" className={styles.dragIco} onClick={() => toggleEdit(index)} />
                              {editOpen && index == clickedIndex && EditLink(clickedIndex)}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                })}{provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }

    </div>
  )



}
