import styles from './styles.module.css';

import { useHistory } from 'react-router-dom';
import yologoicon from '../../images/yologoicon.ico';
import bigPeace from '../../images/bigPeaceLogo90.webp';
import colesPage from './colespage.webp';
import cardF from './cardFront.webp';
import cardB from './cardBack.webp';
import { UnAuthedNav } from '../UnAuthedNav/UnAuthedNav';
import { useEffect, useState } from 'react';
import { useSize } from '../../hooks/useSize';
import { NavBar } from '../NavBar';

import { mdiAccountMultiple } from '@mdi/js';
import Icon from '@mdi/react';


export const Home = () => {
    

    const { size } = useSize();
    useEffect(() => {
        console.log('size', size)
    }, [size]);

    const history = useHistory();
    return (
        <div className={styles.main} >
            <NavBar value={size} />

            <div className={styles.mainContainer}>
                <div className={styles.left}>

                    <div className={styles.txt}> Change how<span className={styles.blueGrad}> you connect</span> forever.</div>
                    <div className={styles.p}>
                        Upgrade your link in bio and business card with yoitsme digital business cards.
                    </div>
                    <div className={styles.p}>
                        Use your link yoits.me/yourname to share in social media bios or to send online.
                    </div>
                    <div className={styles.p}>
                        Upgrade your networking even more to share with your smart YoCard in person.
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.signup}
                            onClick={() => history.push('/signup')}
                        >
                            Signup</div>
                        <div className={styles.login}
                            onClick={() => history.push('/login')}
                        >
                            Login</div>
                    </div>
                </div>
                <div className={styles.right}>
                    <img src={colesPage} className={styles.colesPage} />
                    <img src={cardF} className={styles.cardF} />

                    {size.width >= 1045 && <img src={cardB} className={styles.cardB} />}
                </div>


            </div>

            <div className={styles.subContainer}>
                <button onClick={() => history.push('/team-signup')} className={styles.teamSignupBtn}
                >

                    <div>
                        Create an enterprise account
                    </div>
                    <Icon path={mdiAccountMultiple} size={1} style={{marginLeft:"3px"}} />
                </button>

            </div>






        </div>


    );

}