import { useEffect } from "react";
import { useState } from "react";
import { SketchPicker } from "react-color"
import styles from './styles.module.css';


export const ColorPicker = ({ pageBColor, setPageBColor, userIconColor, setUserIconColor, iconBackgroundColor, setIconBackgroundColor, mode, handleClose }) => {

    const [initialColor, setInitialColor] = useState()

    useEffect(() => {
        if (mode === "iconColor") {
            setInitialColor(userIconColor)
        }
        if (mode === "backgroundColor") {
            setInitialColor(iconBackgroundColor)
        }
        if (mode === "pBColor") {
            setInitialColor(pageBColor)
        }

    }, [userIconColor, pageBColor, iconBackgroundColor])

    const OnComplete = (newColor) => {
        if (mode === "iconColor") {
            setUserIconColor(newColor)
        }
        if (mode === "backgroundColor") {
            setIconBackgroundColor(newColor)
        }
        if (mode === "pBColor") {
            setPageBColor(newColor)
        }
    }

    return (
        <div className={styles.ColorPickerCont}>

            <div className={styles.navBtnRow}>
                <div className={styles.leftBtnCont}>
                    <button
                       // onClick={() => { history.push('/') }}
                        className={styles.navBtn}>Back</button>
                </div>
                <div className={styles.rightBtnGroup}>

                    <button
                        //  style={{ color: '#007AFF'  }}
                        // onClick={() => { setSortingByRecent(true) }}
                       // onClick={updatePage}
                        className={styles.saveBtn}>Save</button>
                </div>
            </div>
            <SketchPicker
                className={styles.SketchPicker}

                color={initialColor}
                onChange={(newColor) => OnComplete(newColor.hex)}
                onChangeComplete={(newColor) => OnComplete(newColor.hex)}
            />
        </div>
    )

}