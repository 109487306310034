

import { async } from "@firebase/util";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks";
import { fb } from "../../shared/service";
import { GetCompanyInfo } from "../DashboardAdmin/GetCompanyInfo"



export const AdminAnalytics = data => {
    const { authUser } = useAuth();
    const location = useLocation();
    const [analytics, setAnalytics] = useState();
    //const {companyId, companyInfo} = GetCompanyInfo();
    const { teamPages, companyId, companyInfo } = location.state;
    const [allData, setAllData] = useState();
    useEffect(() => {
        if (companyId || companyInfo) {
            //console.log('ID', companyId, 'Info', companyInfo);
        }

    }, [companyId, companyInfo]);
    useEffect(() => {
        if (teamPages) {
            console.log('!!!teamPages from Analytics', teamPages);
        }
    }, [teamPages]);

    useEffect(() => {

        if (companyId) {
            // const pageIds = ['aaa47b4a-7317-4343-af7d-defa6b4d287f','a3a47b4a-7617-4343-af7d-def36b4d287f' ];
            // const pageId = 'aaa47b4a-7317-4343-af7d-defa6b4d287f';
            const pageIds = teamPages.map(p => p.pageId);

            const getVisitors = async () => {
                const fullData = [];
                for (const pageId of pageIds) {
                    const visitorDocRef = doc(fb.firestore, `analytics/${companyId}/${pageId}/visitors`);
                    const visitorsDocSnap = await getDoc(visitorDocRef);
                    if (visitorsDocSnap.exists()) {
                        const visitorsData = visitorsDocSnap.data();
                        visitorsData.pageId = pageId;
                        fullData.push(visitorsData)
                      // fullData[pageId] = visitorsData;
                    }
                    //  console.log('pageId', pageId, visitorsDocSnap.data());
                }
                setAllData(fullData);
            }
            getVisitors();

        }
    }, [companyId]);
    useEffect(() => {
        if (allData) {
            console.log("all Data", allData)
            console.log(allData[0].pageVisits)
        }
    }, [allData])

    /*
  
    useEffect(() => {
      if(companyId){
  
         const unsubscribe = data
         ? fb.firestore.collection('analytics').where('name', "==", companyId)
         .onSnapshot(snap => {
          const a = [];
          snap.forEach(s => {
              a.push({
                  ...s.data(),
                  id: s.id,
              });
          });
          setAnalytics(a)
         })
         : undefined;
         return unsubscribe;
  
      }
    },[companyId]);*/
    useEffect(() => {
        if (analytics) {
            console.log('Company Analytics', analytics);
        }

    }, [analytics]);

    return (
        <div>
            AdminAnalytics

            {teamPages &&
                <div>
                    {teamPages?.map((l, index) => {
                        const analyticsData = allData?.find(data => data.pageId === l.pageId);
                        return (
                            <div key={l.pageId}
                            >
                                {l.userFullName} Page Visits: {analyticsData && analyticsData.pageVisits.length} Clicks: {analyticsData && analyticsData.btnClicks.length}
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}