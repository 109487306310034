import styles from "./styles.module.css";



export const TOC = () => {


    return (
        <div className={styles.main}>
            <div className={styles.txtCont}>
                <div className={styles.h1}>
                    Terms and Conditions
                </div>
                <div className={styles.p}>
                YoTech LLC “(yoits.me” “us”, “we”, or “our”), own or license all right, title, and interest in and to the website located at yoitsme.app and yoits.me (“Website”) and any or all related applications ("App" or "Apps"), physical products ('Product" or "Products"), or a collection of these as a service (“Service” or “Services”). All references to “YOU”, “YOUR”, or the "USER" refers to the person accessing or using the Services. A "USER" is a person that has either signed up for a Paid and or Free account, is a website visitor, or someone directly or indirectly using any of our Products or Services. 
                </div>
                <div className={styles.p}>
                Your use of the Services is subject to our Terms of Use. These terms and conditions ("Terms and Conditions") explain some rights you give up using or accessing our Services. These Terms and Conditions can change over time and can always be accessed at https://yoits.me/terms                    
                </div>
                <div className={styles.p}>
                PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY IT AFFECTS YOUR RIGHTS. BY ACCESSING THE SERVICES OR SUBMITTING YOUR PERSONAL INFORMATION YOU AGREE TO THE TERMS AND CONDITIONS AND PRIVACY POLICY. IF YOU DO NOT ACCEPT THE TERMS AND CONDITIONS AND OR THE PRIVACY POLICY, DO NOT ACCESS OR USE THE SERVICE.                    
                </div>

                <div className={styles.h2}>
                1. Hold Harmless Agreement
                </div>
                <div className={styles.p}>
                YOU the USER hereby release, waive, discharge, and covenant not to sue YoTech LLC, and any of their agents, representatives, or employees ("RELEASEES") for any liability, claim, and or cause of action arising out of or related but not limited to any security breach, loss of data, or irreparable damage (including but not limited to monetary and reputation) that occurs. The USER agrees to indemnify and hold harmless the RELEASEES whether data loss is caused by the USER's negligence, the negligence of the RELEASEES, or the negligence of a third party. 
                </div>

                <div className={styles.h2}>2. Release and Waiver of Liability</div>
                <div className={styles.p}>The USER further agrees that this Release and Waiver of Liability shall bind the USER. The USER hereby further agrees that this Release and Waiver of Liability shall be construed in accordance with the laws of the State of Deleware, United States of America. By accessing the services the USER agrees to the Release and Waiver of Liability, I the USER state that I have read and understand the Terms and Conditions outlined in this Release and that I agree to all conditions set forth herein, and that I accepted this voluntarily.</div>
                <div className={styles.h2}>3. Our Rights</div>
                <div className={styles.p}>We reserve the right to delete alter or replace any content provided by you as we see fit. We reserve the right to cancel and delete any plans accounts or Services as we see fit. We reserve the right to cancel, pause, stop or limit our Services whenever we need and or choose to do so. We reserve the right to deny service to any person as we see fit. We reserve the right to implement and enforce these Terms and Conditions as we choose.</div>
                <div className={styles.h2}>4. Disclaimer</div>
                <div className={styles.p}>You are using the Service at your own risk and although we put in place the best practices we cannot ensure there will be no issues. We make and promise no warranties about any of our Services, affiliates, or third parties.</div>
                <div className={styles.h2}>5. Changes to Terms and Conditions</div>
                <div className={styles.p}>We may update our Terms and Conditions as we choose, therefore, important that you regularly review them. We will notify you of any changes by posting the new Terms and Conditions on the Service. We will let you know via email and/or a notice on our Service prior to the change becoming effective and update the “effective date” of these Terms and Conditions. Changes to these Terms and Conditions are effective when they are posted on this page unless otherwise set out on this page, in the email, and/or notice.</div>
                <div className={styles.h2}>6. Contact Us</div>
                <div className={styles.p}>If you have any questions, comments, or concerns about Terms and Conditions, Hold Harmless Agreement, or Release and Waiver of Liability please contact us at info@yoits.me</div>
                <div className={styles.p}>These Terms and Conditions v1.2 were last updated on June, 1st 2022.</div>
            </div>

        </div>

    );
};


