import styles from "./styles.module.css";



export const Priv = () => {


    return (
        <div className={styles.main}>
            <div className={styles.txtCont}>
                <div className={styles.h1}>
                    Privacy Policy
                </div>
                <div className={styles.p}>YoTech LLC ("yoits.me” “us”, “we”, or “our”), ensures the best practices to maintain your privacy and keep your personal information secure. This privacy policy ("Privacy Policy") explains what happens with your information. This Privacy Policy can change over time and can always be accessed at https://yoits.me/privacy.
                </div>
                <div className={styles.p}>All references to “YOU”, “YOUR”, or the "USER" refers to the person accessing or using the Services as outlined in the Terms and Conditions. A "USER" is a person that has either signed up for a Paid and or Free account, is a website visitor, or someone directly or indirectly using any of our Products or Services.
                </div>
                <div className={styles.h2}>1. Personal Information
                </div>
                <div className={styles.p}>Personal Information is information or data about a living individual that can be identified by that information or data (including information or data in our possession or information and data that we acquire into our possession.
                </div>
                <div className={styles.p}>We collect different types of personal information for many different functions as shown below. We collect personal information like name, email address, username, approximate location, language, browser type, device type. When making a purchase you will be using our payment processor Stripe, we do not collect any of your payment information and this is all handled through Stripe's systems.
                </div>
                <div className={styles.h2}>2. Location Data
                </div>
                <div className={styles.p}>We may use and store information about your approximate location like your country, city, or postcode using geographic information from your IP address. We use this data to improve and better tailor our services for a more improved experience for users.
                </div>
                <div className={styles.h2}>3. Marketing Data
                </div>
                <div className={styles.p}>This is information and data that regards your preferences to receiving marketing information from us. Or information you provided to a user to receive marketing information from them.
                </div>
                <div className={styles.h2}>4. Cookies Data
                </div>
                <div className={styles.p}>Cookies are files with small amounts of data that may include an anonymous unique identifier. These cookies are sent to your browser from a website and then stored on your device.
                </div>
                <div className={styles.p}>We may use cookies and other similar technologies or services to track the activity on our services.
                </div>
                <div className={styles.h2}>5. Sensitive Data
                </div>
                <div className={styles.p}>We do not collect any sensitive personal information about you like your race or ethnic origin, political opinions or associations, religious beliefs or affiliations, philosophical beliefs, membership of a professional or trade association or union, sexual orientation or practices, and criminal record information. We also do not collect any information about your health, genetic background and or biometric personal information.
                </div>
                <div className={styles.h2}>6. Uploaded Personal Information
                </div>
                <div className={styles.p}>We do not sell, exchange, or distribute any of the information you upload on our service. This includes contact card information uploaded to a user's page to share their contact. Contact card information is any information that you would normally find or choose to store on a contact in a phone. Any exchanges of data will be approved by the user prior. These exchanges include data being exchanged with our users.
                </div>
                <div className={styles.h2}>7. Children Data
                </div>
                <div className={styles.p}>Our services are not intended for use by children under the age of 13 years old (the "Age Limit"). If you are under the Age Limit please do not use our services or give us any personal information. If you are a parent or guardian and you are aware of an individual under the Age Limit has provided us with personal information, please contact us. Once notice or discovery we will do our best to erase and or destroy any personal information we may have collected or stored about that individual.
                </div>
                <div className={styles.h2}>8. Security
                </div>
                <div className={styles.p}>Our service providers have implemented security measures designed to protect the Personal Information you share with us, including physical, electronic, and procedural measures. Among other things, we offer HTTPS secure access to most areas on our Services. Our partners also regularly monitor our systems for possible vulnerabilities and or attacks as well as regularly seeking new ways and Third-Party Services for further enhancing the security of our Services and protection of our Visitors’ and Users’ privacy.
                </div>
                <div className={styles.p}>Regardless of the measures and efforts are taken by us or our partners, we cannot and do not guarantee the absolute protection and security of your Personal Information or any other information you upload, publish or otherwise share with us, our partners, or anyone else. We encourage you to set strong passwords for your Account and avoid providing us or anyone with it or any other sensitive Personal Information.
                </div>
                <div className={styles.h2}>9. Third-Party Sites & Links
                </div>
                <div className={styles.p}>Our Service may contain links interfacing with other websites that are not operated by us for your information or convenience. This includes Yo Pages created and populated by the user who created the profile. This Privacy Policy does not apply to such linked third-party websites and services.
                </div>
                <div className={styles.p}>These are websites that operate independently from our service and may be subject to alternative terms of use, including terms concerning the use of personal information. We have not reviewed these third-party websites, we do not control them and we are not responsible for how they are operated, their content, or their privacy policies.
                </div>
                <div className={styles.p}>If you click, access, or interact with any of these third-party websites, you are doing so at your own risk. Therefore, we strongly encourage you to review the privacy policy and terms & conditions of each website you visit.
                </div>
                <div className={styles.h2}>10. California Privacy Rights
                </div>
                <div className={styles.p}>California’s “Shine the Light” law, states California residents who provide personal information in obtaining products or services for personal, family, and or household use are entitled to request and obtain your Personal Information we shared, if any, with affiliates or third parties for their direct marketing uses. If applicable, this information would include the categories of the Personal Information and the names and addresses of those businesses with which we shared customer information for the immediately prior calendar year (e.g. requests made in 2021 will receive information regarding 2020 sharing activities).
                </div>
                <div className={styles.p}>To obtain this information, please send an email message to us with “Request for California Privacy Information” on the subject line and in the body of your message with your name and user information. We will provide the requested information to you via your e-mail address in response. Please note that not all information sharing is covered by the “Shine the Light” requirements and we will only be sending the required information.
                </div>
                <div className={styles.h2}>11. Changes to Privacy Policy
                </div>
                <div className={styles.p}>We may update our Privacy Policy as we choose, therefore, important that you regularly review it. We will notify you of any changes by posting the new Privacy Policy on the Service. We will let you know via email and/or a notice on our Service prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy. Changes to this Privacy Policy are effective when they are posted on this page unless otherwise set out on this page, in the email, and/or notice.
                </div>
                <div className={styles.h2}>12. Contact Us
                </div>
                <div className={styles.p}>If you have any questions, comments, or concerns about this Privacy Policy, our privacy practices, or if you would like to make a request about any personal information we may hold about you, including correction of personal information, please contact us at info@yoits.me
                </div>
                <div className={styles.p}>This Privacy Policy v1.2 was last updated on June, 2nd 2022.
                </div>

            </div>

        </div>

    );
};


