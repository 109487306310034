import Icon from '@mdi/react';

import styles from './styles.module.css';
import QR from '../../images/qr.svg';
import GRB from '../../images/greyRadioBtn.svg';
import PRB from '../../images/purpleRadioBtn.svg';
import graph from '../../images/graphWave.svg';
import gear from '../../images/gear.svg';
import contact from '../../images/contacts.svg';
import livePage from '../../images/livePage.svg';
import { useEffect, useState } from 'react';
import { AuthedNav } from '../AuthedNav';
import { useAuth, usePages, useSelectedPage } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { mdiAccountGroup } from '@mdi/js';
import { style } from '@mui/system';

export const Dashboard = () => {
    const { authUser } = useAuth();

    const history = useHistory();
    //const selectedPage = useSelectedPage();
    const pages = usePages(authUser?.uid);
    const [page, setPage] = useState();


    /*useEffect(() => {
        if (selectedPage) {
            console.log('from dashboard selected page', selectedPage);
            setPage(selectedPage[0]);
        }

    }, [selectedPage]);*/
    useEffect(() => {
        if (pages) {
            console.log('from dashboard selected page', pages);
            setPage(pages[0]);
        }

    }, [pages]);

    useEffect(() => {
        if (page) {
            console.log('from dashboard page [0]', page);
            //  setPage(selectedPage[0]);
        }

    }, [page]);

            const viewPage = () => history.push(`/${page?.id}`);

            const editPage = () => history.push({
                pathname: `/edit-page-content/${page?.id}`,
                state: page
            });

            const [path, setPath] = useState('');
            const analytics = () => {
                
                if(page?.isAdmin && page?.companyId){
                    setPath(`/admin-analytics/${page?.id}`)
                } else {
                    setPath(`/analytics/${page?.id}`)
                }
                
                history.push({       
                pathname: path,
                state: page
            })};
            const virtualPage = () => history.push({
                pathname: `/virtual-page/${page?.id}`,
                state: page
            });
            const openSettings = () => history.push({
                pathname: `/settings/${page?.id}`,
                state: page
            });






    return (
        <div className={styles.outside} >
            <AuthedNav className={styles.authedNav} />

            <div className={styles.main}>
                {page?.isAdmin && page?.companyId && 
                <button onClick={() => {history.push("/admin/dashboard")}} className={styles.viewTeamDash}
                ><Icon path={mdiAccountGroup} size={1} className={styles.teamIcon}/>View Team Dashboard</button>}
                <div className={styles.title}>Dashboard</div>

                <div className={styles.btnContainer}>

                    <div className={styles.leftCol}>


                        <div className={styles.sqrBtn} onClick={analytics}>
                            <div className={styles.iconBox} >
                                <img src={graph} />

                            </div>
                            <div className={styles.txtBox}>
                                Analytics

                            </div>
                        </div>


                        <div className={styles.sqrBtn} onClick={virtualPage}>
                            <div className={styles.iconBox}>
                                <img src={QR} />


                            </div>
                            <div className={styles.txtBox}>
                                Virtual Card

                            </div>
                        </div>

                        <div className={styles.sqrBtn} onClick={viewPage}>
                            <div className={styles.iconBox}>
                                <img src={livePage} />


                            </div>
                            <div className={styles.txtBox}>
                                Live Page

                            </div>
                        </div>



                    </div>

                    <div className={styles.rightCol}>


                        <div className={styles.sqrBtn} onClick={editPage}>
                            <div className={styles.iconBox}>
                                <img src={PRB} />
                                <img src={GRB} />

                            </div>
                            <div className={styles.txtBox}>
                                Edit Page

                            </div>
                        </div>

                        <div className={styles.sqrBtn}
                            onClick={() => {
                                history.push({
                                    pathname: `/my-contacts/${page?.pageId}`,
                                    state: page
                                })
                            }}
                        >
                            <div className={styles.iconBox}>
                                <img src={contact} />


                            </div>
                            <div className={styles.txtBox}>
                                Contacts

                            </div>
                        </div>

                        <div className={styles.sqrBtn} onClick={openSettings}>
                            <div className={styles.iconBox}>
                                <img src={gear} />


                            </div>
                            <div className={styles.txtBox}>
                                Settings

                            </div>
                        </div>




                    </div>
                </div>


            </div>
        </div>
    )
}