import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import { fb } from "../../shared/service";
import { GetCompanyInfo } from "../DashboardAdmin/GetCompanyInfo"



export const GetCompanyLinkPages = () => {
    const { companyId } = GetCompanyInfo();
    const [linkPages, setLinkPages] = useState()

    const getLinkPages = async () => {
        //   const docRef = doc(fb?.firestore, 'companyLinkPages', companyId);
        //const res = await getDoc(docRef);
        const snapShot = await fb.firestore.collection('companyLinkPages').doc(companyId).collection('departments').get();

        //if (snapShot.exists()) {
        console.log('docs snapshot', snapShot.docs.map(doc => doc.data()))
        //setLinkPages(res.data());
        setLinkPages(snapShot.docs.reduce((result, doc) => {
            result[doc.id] = doc.data();
            return result;
        }, {}));
        //  } else {
        // console.log('error')
        // }
    }

    useEffect(() => {
        if (companyId) {
            getLinkPages();
        }
    }, [companyId]);

    return linkPages;
}