import styles from './styles.module.css';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useState } from "react";
import { GetCompanyInfo } from "./GetCompanyInfo";



export const FilterDepartment = ({department, setDepartment}) => {
    const {companyInfo} = GetCompanyInfo();


    const filterDepartment = (event) => {
        setDepartment(event.target.value);
    };

    // /

    return (
        <FormControl sx={{ m: 1, minWidth: 300, height: 33, marginBottom: "33px" }} className={styles.filterCenter} variant="standard"> 
            <InputLabel>Filter by Department</InputLabel>
            <Select
                value={department}
                onChange={filterDepartment}
                autoWidth
                label="Department"
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {companyInfo?.departments?.map((dep) => (
                    <MenuItem key={dep.index} value={dep.label}>
                        {dep.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )

}