import { async } from "@firebase/util";
import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth, usePages } from "../../hooks"
import { urlRegex, uuid } from "../../shared";
import { fb } from "../../shared/service";
import { AuthedNav } from "../AuthedNav";
import { FilterDepartment } from "../DashboardAdmin/FilterDepartment";
import { GetCompanyInfo } from "../DashboardAdmin/GetCompanyInfo";
import { Drag } from "../Drag/Drag";
import { Loading } from "../Loading/Loading";
import { GetCompanyLinkPages } from "./GetCompanyLinkPages";
import styles from './styles.module.css';

export const CreateNewButton = ({ currentLink, setCurrentLink, toggleLinkParams, pageLinks, setPageLinks }) => {
    const [validLURL, setValidLURL] = useState();
    useEffect(() => {
        setValidLURL(currentLink.url && urlRegex.test(currentLink.url) && currentLink.url.length <= 2000)

    }, [currentLink.url]);

    return (
        <div className={styles.popBackground}>
            <div className={styles.linkInputs}>
                <div className={styles.closeCont}> <Icon path={mdiClose} size={1} className={styles.closeSocialPop} onClick={toggleLinkParams} /></div>
                <div className={styles.titleS}>Create a new Link:</div>
                <div className={styles.linkInputBox}>
                    <div className={styles.pageNameTitle}>Link Title:</div>
                    <input
                        className={styles.txtA}
                        placeholder="Link Title"
                        value={currentLink.name}
                        onChange={e => setCurrentLink(
                            { ...currentLink, name: e.target.value })}
                    />
                    <div className={styles.pageNameTitle}>Link Url:</div>
                    <input
                        className={styles.txtA}
                        placeholder="Link Url"
                        value={currentLink.url}
                        onChange={e => setCurrentLink({ ...currentLink, url: e.target.value })}
                    />
                </div>
                <button
                    onClick={() => {
                        setPageLinks([...pageLinks, { name: currentLink.name, url: currentLink.url, id: uuid() }]);
                        setCurrentLink({ url: '', name: '', id: '' });
                        toggleLinkParams();
                    }}
                    disabled={!currentLink.name || !currentLink.url || !validLURL}
                    className={styles.addSocialBtn}>Add Link
                </button>
            </div>
        </div>
    )
}


export const EditTeamPagesAdmin = () => {
    const { authUser } = useAuth();
    const pages = usePages(authUser?.uid);
    const [department, setDepartment] = useState('');
    // const [companyId, setCompanyId] = useState();
    // const [companyInfo, setCompanyInfo] = useState();
    const { companyId, companyInfo } = GetCompanyInfo();
    const [headline, setHeadline] = useState('');
    const [pageLinks, setPageLinks] = useState([]);
    const [currentLink, setCurrentLink] = useState({ url: '', name: '', id: '' });
    const [linkCOpen, setLinkCOpen] = useState(false);
    const [biography, setBiography] = useState('');
    const [taHeight, setTaHeight] = useState();
    const cLinkPages = GetCompanyLinkPages();
    const [loading, setLoading] = useState(false);

    function handleBio(event) {
        setBiography(event.target.value);
        const h = event.target.scrollHeight;
        const rows = event.target.rows;
        const rowHeight = 15;
        const trows = Math.ceil(h / rowHeight) - 1;
        if (trows) {
            setTaHeight(trows);
        }
    }

    const [companyPages, setCompanyPages] = useState()

    useEffect(() => {
        if (cLinkPages) {
            setCompanyPages(cLinkPages);
        }
    }, [cLinkPages]);

    useEffect(() => {
        if (companyPages) {
            console.log('GETCOMPANYLINKPAGES', companyPages)
        }
    }, [companyPages]);
    useEffect(() => {
        console.log('page LINKS =>', pageLinks)
    }, [pageLinks])


    useEffect(() => {
        if (department) {
            console.log('department from Edit team pages', department)
        
            if (companyPages) {
                if (companyPages[department]) {
                    setHeadline(companyPages[department].headline);
                    setBiography(companyPages[department].biography);
                    setPageLinks(companyPages[department].links);
                } else {
                    setHeadline('');
                    setBiography('');
                    setPageLinks([]);
                }
            }


        }
    }, [department]);

    useEffect(() => {
        if (companyId && companyInfo) {
            console.log('GETCOMPANY companyId', companyId, 'companyinfo', companyInfo)
        }
    }, [companyId, companyInfo])

    const toggleLinkParams = () => {
        setLinkCOpen(!linkCOpen);
        setCurrentLink({ url: '', name: '', id: '' });
    };


    const sendCompanyLinkPages = async () => {
        setLoading(true);
        //   const docRef = doc(fb.firestore.collection('companyLinkPages'), companyId);
        const docRef = fb.firestore.collection('companyLinkPages').doc(companyId).collection('departments').doc(department);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log('exists');
            try {
                await updateDoc(docRef, {
                    //  [department]: {
                    headline: headline,
                    biography: biography,
                    links: pageLinks
                    // }
                }, { merge: true })
                console.log('linkpages updated');
                setLoading(false);
               
            } catch (error) {
                console.error('error updating field', error)
            }
            //if company linkpages for departments already exist use the .update() function to create new path/data
        } else {//if not use set() to add changes to pre-existing doc
            try {
                await setDoc(docRef, {
                    // [department]: {
                    headline: headline,
                    biography: biography,
                    links: pageLinks
                    // }
                }, { merge: true })
                console.log('linkpages set')
                setLoading(false);
            } catch (error) {
                console.error('error updating field', error)
            }
        }
    }



    return (
        <div className={styles.main2}>
            <AuthedNav />
            <FilterDepartment department={department} setDepartment={setDepartment} />

            {department &&
                <div className={styles.main}>
                    <div className={styles.infoInputsBox}>
                    <div className={styles.title}>
                            Details
                        </div>
                    <div className={styles.pageNameTitle}>
                        Headline:
                    </div>
                    <input className={styles.txtInput}
                        value={headline}
                        placeholder='Headline'
                        onChange={e => setHeadline(e.target.value)}
                        id="headlineInput"
                    ></input>

                    <div className={styles.pageNameTitle}>Biography:</div>
                    <textarea
                        className={styles.inputBio}
                        value={biography}
                        placeholder={'Biography...'}
                        onChange={handleBio}
                        rows={taHeight}

                    //  ref={textAreanRef}
                    >

                    </textarea>
                    </div>

                    <h2>Buttons</h2>
                    <button onClick={toggleLinkParams} className={styles.openSocialBox}>Create a New Button <Icon path={mdiPlus} size={1} /></button>
                    {linkCOpen && <CreateNewButton currentLink={currentLink} setCurrentLink={setCurrentLink} toggleLinkParams={toggleLinkParams} pageLinks={pageLinks} setPageLinks={setPageLinks} />}

                    {pageLinks && <Drag pageLinks={pageLinks} setPageLinks={setPageLinks} />}


                    <button onClick={sendCompanyLinkPages} className={styles.submit}
                    >Save Changes</button>
                    {loading && <Loading/>}
                </div>}


        </div >
    )
}