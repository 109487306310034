import { fb } from '../shared/service';
import { useEffect, useState } from 'react';
import { useAuth } from '.';

export const usePages = userId => {
 

  const [pages, setPages] = useState();

  useEffect(() => {
    const unsubscribe = userId
      ? fb.firestore
        .collection('linkPages')
        .where('userId', '==', userId)
        .onSnapshot(snap => {
          const _pages = [];
          snap.forEach(s => {
            _pages.push({
              ...s.data(),
              id: s.id,
            });
          });
          setPages(_pages);
        })
      : undefined;

    return unsubscribe;
  }, [userId]);

  return pages;
};

export const useSelectedPage = () => {
  const { authUser } = useAuth();
   const pages = usePages(authUser?.uid);
  //const pages = pages;
  const [selectedPage, setSelectedPage] = useState(undefined);

  function checkCurrentPage(pages) {
    return pages?.filter((element) => {
      return element.pageSelected === true;
    })
  }

  useEffect(() => {
    if (pages) {
   
      setSelectedPage(checkCurrentPage(pages));
    }

  }, [pages]);



  if (selectedPage !== undefined) {
    return selectedPage;
  }
};



