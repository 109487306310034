import styles from './styles.module.css';
import { MdEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { fb } from '../../shared/service';
import { useEffect } from 'react/cjs/react.development';
import { deleteObject, ref } from 'firebase/storage';
import Icon from '@mdi/react';
import { mdiSend, mdiTrashCan } from '@mdi/js';
import { Identity } from '@mui/base';
import { doc, getDoc } from 'firebase/firestore';
import { useState } from 'react';


export const DashboardLinkAdmin = ({ page }) => {
  const history = useHistory();

  const viewPage = () => window.open(`/${page.name}`);
  const editPage = () => history.push(`/edit-page-details/${page.name}`);
  const deletePage = () => {
    if (window.confirm(`Are you sure you want to delete ${page.name}?`)) {
      fb.firestore.collection('linkPages').doc(page.name).delete();
      const storage = fb.storage;
      const deletePic = ref(storage, `images/profilePic/${page.pageId}`);
      deleteObject(deletePic).then(() => {

      }).catch((error) => {
        console.log('image delete error');
      });
      const deleteVCF = ref(storage, `contacts/${page.pageId}`);
      deleteObject(deleteVCF).then(() => {

      }).catch((error) => {
        console.log('vcf delete error');
      });
    }
  };

  async function sendSigninEmail() {
    console.log('page uid', page.userId)
    if (page.department) {
      const infoDocRef = doc(fb?.firestore, "companyInfo", page?.companyId);
      const companyData = await getDoc(infoDocRef);
      const departments = companyData.data().departments;
      const department = departments.find((d) => d.label === page.department);
      if (department) {
        const user = department.users.find((u) => u.uid === page.userId);
        if (user) {
          console.log('userEmail', user.email);
          console.log('department', department.label)
          fb?.auth?.sendSignInLinkToEmail(user.email, {
           // url: `${window.location.origin}/employee-signin/${page.companyId}/${department.label}/${user.email}/${page.userFullName}`,
           url: `https://yoitsme.co/employee-signin/${page.companyId}/${department.label}/${user.email}/${page.userFullName}`,
            handleCodeInApp: true,
          })
            .then(() => {
              // The link was successfully sent. Inform the user.
              console.log('send employee runing p2')
            })
            .catch((err) => {
              console.log('error sending link', err);
            })
          return user.email;
        }
      }
      console.log('department', department);
    }
  }
  console.log('dash links {page}:' + page?.pageId);
  return (
    <div className={styles.main}>
      <div onClick={() => { history.push(`/analytics/${page?.pageId}`) }} className={styles.title}>/ {page?.name}</div>

      <div onClick={viewPage} className={styles.view}>
        <MdRemoveRedEye />
      </div>
      <div onClick={editPage} className={styles.edit}>
        <MdEdit />
      </div>
      <div onClick={deletePage} className={styles.delete}>
        <Icon path={mdiTrashCan} />
      </div>
      <div onClick={sendSigninEmail} className={styles.send}>
        <Icon path={mdiSend} />
      </div>
    </div>
  );
};