import { Box, CircularProgress } from "@mui/material"



export const Loading = () => {



    return(
        <Box>
            <CircularProgress />
        </Box>
    )
}
