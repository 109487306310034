import React, { useState, useEffect, Fragment, useRef } from "react";
import styles from './styles.module.css';
import { MdEdit } from 'react-icons/md';
import Icon from "@mdi/react";
import { mdiClose, mdiEyedropper, mdiFormatColorFill, mdiPlus, mdiTrashCan } from "@mdi/js";
import { mdiApple, mdiApplicationSettings, mdiBasket, mdiDiscord, mdiFacebook, mdiGift, mdiInstagram, mdiLinkedin, mdiLinkVariant, mdiPinterest, mdiPlusCircleOutline, mdiPodcast, mdiShareVariantOutline, mdiSnapchat, mdiSoundcloud, mdiSpotify, mdiTwitch, mdiTwitter, mdiWhatsapp, mdiYoutube } from "@mdi/js";
import { CgProfile, CgReorder } from 'react-icons/cg';
import { emailRegex, uuid } from "../../shared";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { fb } from "../../shared/service";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, getBlob } from "firebase/storage";
import imageCompression from 'browser-image-compression';
import { useAuth } from "../../hooks";
import 'firebase/storage';
import { VCard } from '../VCardCreator';
import { AuthedNav } from "../AuthedNav";
import { Drag } from "../Drag/Drag";
import stockPic from '../../images/noProfilePic.svg';
import { urlRegex } from "../../shared/constants";
import { CropperD } from "../CropperD/CropperD";
import ReactSwitch from "react-switch";
import { NeedToLogin } from "../NeedToLogin";
import { DragSocials } from "../DragSocials/DragSocials";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { AddBtnPic } from "./AddBtnPic";

export const CreatePage = () => {
    const history = useHistory();
    const { authUser } = useAuth();
    const [pageName, setPageName] = useState('');
    const [fullName, setFullName] = useState('');
    const [headline, setHeadline] = useState('');
    const [biography, setBiography] = useState('');
    const [pageLinks, setPageLinks] = useState([]);
    const [checkedD, setCheckedD] = useState(undefined);
    const [checkedX, setCheckedX] = useState(undefined);
    const [currentLink, setCurrentLink] = useState({ url: '', name: '', id: '' });//uuid <(id) may need to be exchanged with index later. this may help users customize the order in which their pages render
    const [isOpen, setIsOpen] = useState(false);//for social icon drop down
    const [isEdit, setIsEdit] = useState();
    const [pageId, setPageId] = useState(null);
    const [uid, setUid] = useState();
    //const [userFullName, setUserFullName] = useState(null);
    const { id } = useParams();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [dragPage, setDragPage] = useState(Drag.characters);
    const [page, setPage] = useState();
    const [linkCOpen, setLinkCOpen] = useState(false);
    const [cropperOpen, setCropperOpen] = useState(false);
    const [needToLogin, setNeedToLogin] = useState(true);
    const [pageBColor, setPageBColor] = useState('');
    const [itemsChanged, setItemsChanged] = useState(0);

    useEffect(() => {
        console.log('ITEMS CHANGED = >', itemsChanged);
    }, [])

    useEffect(() => {
        setDragPage(Drag?.characters);
        console.log('dragPage from CP.JS', dragPage, pageLinks);

    }, [Drag.characters, pageLinks]);




    useEffect(() => {
        // console.log('auth user', authUser)

    }, [authUser]);




    const [fbRan, setFbRan] = useState(false);

    useEffect(() => {
        if (authUser && id && location.pathname.includes('/edit-page-content')) {
            setIsEdit(true);
            fb.firestore
                .collection('linkPages')
                .doc(id)
                .get()
                .then(res => {
                    const data = res.data();
                    if (data) {
                        setUid(data.userId);
                        setPageName(data.name);
                        setPageLinks(data.links);
                        setUserSocials(data.socials);
                        setFullName(data.userFullName);
                        setHeadline(data.headline)
                        setBiography(data.userBio);
                        setPageId(data.pageId);
                        setUserIconColor(data.iconColor);
                        setIconBackroundColor(data.iconBColor);
                        setPageBColor(data.pBColor);
                        setCheckedD(data.showContactD);
                        setCheckedX(data.showContactX);
                        setIsLoading(false);
                        setPage(data);
                        setFbRan(true);
                    } else {
                        console.error(404);

                    }
                });

        } else {
            setIsEdit(false);
            setPageId(uuid());

        }
    }, [authUser, id, location]);




    useEffect(() => {//Pulls Image From Firebase If Updating page(need to make sure it doesnt break if pic is null)
        if (isEdit == true) {
            const storageD = getStorage();
            getDownloadURL(ref(storageD, `images/profilePic/${uid}/${pageId}`))
                .then((url) => {
                    setImage(url);
                    console.log('Create page get img url useeffect ran and setImage(url)')
                })
        }
    }, [pageId]);

    //////////////////////////
    const [color] = useState({ black: '#000000', purple: '#A082FC', white: '#FFFFFF' });
    const [userIconColor, setUserIconColor] = useState();
    const [iconBackroundColor, setIconBackroundColor] = useState();
    useEffect(() => {
        if (userIconColor == color.white) {
            setIconBackroundColor(color.black);
        } else if (userIconColor == color.black || userIconColor == color.purple) {
            setIconBackroundColor(color.white);
        }
    }, [userIconColor]);

    const [mapTest, setMapTest] = useState([
        [{ name: "Facebook" }, { logo: mdiFacebook }, { url: '' }],
        [{ name: 'Twitter' }, { logo: mdiTwitter }, { url: '' }],
        [{ name: 'Linkedin' }, { logo: mdiLinkedin }, { url: '' }],
        [{ name: 'Snapchat' }, { logo: mdiSnapchat }, { url: '' }],
        [{ name: 'Instagram' }, { logo: mdiInstagram }, { url: '' }],
        //[{name: 'TikTok'}, {logo: }, {url: {}}],
        [{ name: 'Shop' }, { logo: mdiBasket }, { url: '' }],
        [{ name: 'Donate' }, { logo: mdiGift }, { url: '' }],
        [{ name: 'Apple' }, { logo: mdiApple }, { url: '' }],
        [{ name: 'Podcast' }, { logo: mdiPodcast }, { url: '' }],
        [{ name: 'Discord' }, { logo: mdiDiscord }, { url: '' }],
        [{ name: 'SoundCloud' }, { logo: mdiSoundcloud }, { url: '' }],
        [{ name: 'Spotify' }, { logo: mdiSpotify }, { url: '' }],
        [{ name: 'Twitch' }, { logo: mdiTwitch }, { url: '' }],
        [{ name: 'YouTube' }, { logo: mdiYoutube }, { url: '' }],
        [{ name: 'Share' }, { logo: mdiShareVariantOutline }, { url: '' }],
        [{ name: 'Pinterest' }, { logo: mdiPinterest }, { url: '' }],
        [{ name: 'WhatsApp' }, { logo: mdiWhatsapp }, { url: '' }],
        //   [{name: 'TikTok'}, {logo: 'simple-icons:tiktok'}, {url: ''}],
        [{ name: 'Link' }, { logo: mdiLinkVariant }, { url: '' }],
    ]);
    const [setSocials, setSetSocials] = useState([]);
    const [socialParamsOpen, setSocialParamsOpen] = useState(false);
    const [currentSocialLink, setCurrentSocialLink] = useState({ url: '', name: '', id: '' });
    const [userSocials, setUserSocials] = useState([]);

    //////
    const [image, setImage] = useState(null);
    const [compImg, setCompImg] = useState(null);
    const [imgURL, setImgURL] = useState(null);
    const [imageData, setImageData] = useState();
    const [imgChanged, setImgChanged] = useState(false);
    const [VCF, setVCF] = useState();
    const [VCFChanged, setVCFChanged] = useState(false);
    //////
    const [btnImage, setBtnImage] = useState(null);



    const toggleSocialParams = () => {
        setSocialParamsOpen(!socialParamsOpen);
    };
    const toggleLinkParams = () => {
        setLinkCOpen(!linkCOpen);
        setCurrentLink({ url: '', name: '', id: uuid() });
    };
    const toggleSocialBox = () => {
        setIsOpen(!isOpen);
        setCurrentSocialLink({ name: '', logo: '', url: '', id: '' });
    }
    const toggleCropper = () => {
        setCropperOpen(!cropperOpen);
    }
    useEffect(() => {
        if (isOpen === false) {
            setSocialParamsOpen(false);
            setCurrentSocialLink({ name: '', logo: '', url: '', id: '' });
        }
    }, [isOpen]);


    useEffect(() => {
        if (image == null || undefined) {
            setImage(stockPic);
        }
    }, [image])



    const [imgFile, setImgFile] = useState(null);
    async function handlePicChange(e) {
        const imgFile = e.target.files[0];
        setImgFile(e.target.files[0]);
        setImgChanged(true);
        try {
            setImage((URL.createObjectURL(imgFile)));
            toggleCropper();

        } catch (error) {
            console.log(error);
        }
    }
    const handleVCFChange = e => {
        setVCF((URL.createObjectURL(e.target.files[0])));
        setVCFChanged(true);

    };
    //  console.log(authUser);

    //////////////////////////////////////////////////////////////////

    //const handleSocialURL = e => setCurrentSocialLink({ ...currentSocialLink, url: e.target.value })

    const radioColor = e => {
        //  console.log(e.target.value);
        setUserIconColor(e.target.value);
    }

    ///////////////////////////////////////////////////
    const hiddenFileInput = React.useRef(null);
    const addPicInput = e => {
        hiddenFileInput.current.click();
    };

    const contactFileInput = React.useRef(null);
    const addContactInput = e => {
        contactFileInput.current.click();
    };

    const updatePicture = (e) => {
        const storage = getStorage();
        fetch(image)
            .then(function (response) {
                return response.blob()
            })
            .then(function (image) {
                const storageRef = ref(storage, `images/profilePic/${uid}/${pageId}`);
                uploadBytes(storageRef, image).then((snapshot) => {
                });
            });
    }
    const updateVCF = (e) => {////////////////////////////
        const storage = getStorage();
        fetch(VCF)
            .then(function (response) {
                return response.blob()
            })
            .then(function (VCF) {
                const storageRef = ref(storage, `contacts/${uid}/${pageId}/${fullName}.vcf`);
                uploadBytes(storageRef, VCF).then((snapshot) => {
                     console.log('uploaded');
                    console.log('VCF:' + VCF);
                });
            });
    }
    const [vFormOpen, setVFormOpen] = useState(false);
    const openVForm = () => {
        setVFormOpen(!vFormOpen);
    }
    ///////////////////////////////////////////////////////////////////////////////////
    const createPage = () => {

        if (authUser && pageName && pageLinks.length && fullName.length) {/////////////////info

            fb.firestore.collection('linkPages').doc(pageName.toLowerCase()).set({
                userId: authUser.uid,
                links: pageLinks,
                name: pageName.toLowerCase(),
                pageId: pageId,
                userFullName: fullName,
                headline: headline,
                userBio: biography,
                socials: userSocials,
                iconColor: userIconColor,
                showContactD: checkedD,
                showContactX: checkedX,
            })
                .then(() => {
                    if (imgChanged === true) {
                        const uploadPicture = (e) => {
                            const storage = getStorage();
                            fetch(image)
                                .then(function (response) {
                                    return response.blob()
                                })
                                .then(function (blob) {
                                    const storageRef = ref(storage, `images/profilePic/${uid}/${pageId}`);
                                    uploadBytes(storageRef, blob).then((snapshot) => {
                                    });
                                });
                        }
                        uploadPicture();
                    }
                    if (VCFChanged === true) {
                        updateVCF();
                    }
                    history.push('/');
                });
        }
    };

    const updatePage = () => {
        if (authUser) {
            fb.firestore
                .collection('linkPages')

                .doc(id)
                .update({
                    //  userId: authUser.uid,
                    links: pageLinks,
                    // name: pageName.toLowerCase(),
                    //  pageId: uuid(),                    
                    userFullName: fullName,
                    headline: headline,
                    userBio: biography,
                    socials: userSocials,
                    ...(userIconColor && { iconColor: userIconColor }),
                    //iconColor: userIconColor,
                    ...(iconBackroundColor && { iconBColor: iconBackroundColor }),
                    // iconBColor: iconBackroundColor,
                    ...(pageBColor && { pBColor: pageBColor }),

                    // pBColor: pageBColor,
                    showContactD: checkedD,
                    showContactX: checkedX,

                })

                .then(() => {
                    if (imgChanged === true) {
                        updatePicture();
                    }
                    if (VCFChanged === true) {
                        updateVCF();
                    }

                    history.push('/');
                });
        }
    };
    // console.log(image);
    // console.log(pageLinks);
    //////////////////////////////////////////////////
    const SocialParamsPop1 = () => {
        return (
            <div className={styles.socialParams}>
                <div className={styles.socialName}
                    placeholder={currentSocialLink.name + ':'}
                >{currentSocialLink.name + ' ' + 'url:'}
                </div>

                <input className={styles.txtA}
                    key="currentSocial"
                    autoFocus="autoFocus"
                    placeholder="Social URL"
                    value={currentSocialLink.url}
                    onChange={e => setCurrentSocialLink({ ...currentSocialLink, url: e.target.value })}////////////////////   
                />

                <button
                    className={styles.addSocialBtn}
                    onClick={() => {
                        setUserSocials([...userSocials, { name: currentSocialLink.name, url: currentSocialLink.url, logo: currentSocialLink.logo, id: uuid() }]);
                        setCurrentSocialLink({ name: '', logo: '', url: '', id: '' });
                        // const socialsToSend = userSocials;
                        toggleSocialBox();
                    }}
                    disabled={!currentSocialLink.url || !currentSocialLink.name || !validSURL}
                >
                    Add Social Icon
                </button>

            </div>
        );
    };
    //////////////////////////////////////////////////////URL Validations
    const [validSURL, setValidSURL] = useState(false);

    useEffect(() => {
        setValidSURL(currentSocialLink.url && urlRegex.test(currentSocialLink.url) && currentSocialLink.url.length <= 2000)
        //console.log('currentSocialLink url lenth', currentSocialLink?.url?.length);
    }, [currentSocialLink.url]);


    const [validLURL, setValidLURL] = useState(false);
    useEffect(() => {
        setValidLURL(currentLink.url && urlRegex.test(currentLink.url) && currentLink.url.length <= 2000)
        //console.log('currentSocialLink url lenth', currentSocialLink?.url?.length);
    }, [currentLink.url]);

    useEffect(() => {
        console.log('currentSocialLink equals urlRegex', validLURL);
    }, [validLURL]);


    ////////////////////////////////////////////////
    const [taHeight, setTaHeight] = useState();
    function handleBio(event) {
        setBiography(event.target.value);
        const h = event.target.scrollHeight;
        const rows = event.target.rows;
        const rowHeight = 15;
        const trows = Math.ceil(h / rowHeight) - 1;
        // console.log(h, rows, trows);

        if (trows) {
            //  console.log((trows - rows) + 'more rows');
            setTaHeight(trows);

        }
    }

    const handleCrop = (croppedImg) => {
        // console.log('cropped image transfered',croppedImg);
        setImage(croppedImg);
        setImgChanged(true);
    }

    //handle Contact showing toggle prefrences on public page

    const handleCheckD = (val) => {
        setCheckedD(val);
    };
    const handleCheckX = (val) => {
        setCheckedX(val);
    };

    // {needToLogin && <NeedToLogin />}

    //save reminder
    const [saveOpen, setSaveOpen] = useState(false);
    useEffect(() => {//To detect any changes made in order to remind the user to save changes
        console.log('fbRan', fbRan)
        if (fbRan === false) {
            return;
        }
        if (pageLinks && userSocials && fullName && itemsChanged > 0) {
            console.log('SOMETHING CHANGED USEEFFECT');
            setSaveOpen(true);////////////////////////////////
        }
        //pageLinks, userSocials, fullName, headline, biography, userIconColor, checkedD, checkedX, VCFChanged, pageBColor
    }, [itemsChanged, pageBColor]);

    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [newLinkNeedsPic, setNewLinkNeedsPic] = useState(false);

    const handleLinkPicSwitch = (val) => {
        setNewLinkNeedsPic(val);
    }

    const hiddenBtnPicInput = useRef(null)

    const addBtnPic = () => {
        hiddenBtnPicInput.current.click()
    };
    useEffect(() => {
        console.log('COLOR PICKER BOOL VAL => ', colorPickerOpen)
    }, [colorPickerOpen])

    const toggleColorPicker = () => {
        setColorPickerOpen(!colorPickerOpen);
    }
    // /<AuthedNav />
    return (
        <div className={styles.main2}>

            <div className={styles.navBtnRow}>
                <div className={styles.leftBtnCont}>
                    <button
                        onClick={() => { history.push('/') }}
                        className={styles.navBtn}>Back</button>
                </div>
                <div className={styles.rightBtnGroup}>

                    <button
                        //  style={{ color: '#007AFF'  }}
                        // onClick={() => { setSortingByRecent(true) }}
                        onClick={updatePage}
                        className={styles.saveBtn}>Save</button>
                </div>
            </div>

            {cropperOpen && <CropperD value={image} getCroppedImage={handleCrop} handleClose={toggleCropper} />}
            {saveOpen &&
                <div className={styles.saveRCont}>
                    <div className={styles.saveRBox}>
                        <div className={styles.saveRow}>
                            <button className={styles.cancel}
                                onClick={() => history.push('/')}>
                                Cancel
                            </button>
                            <button
                                onClick={!isEdit ? createPage : updatePage}//, uploadPicture
                                disabled={!pageName || !fullName.length}
                                className={styles.save}>
                                {!isEdit ? 'Create Page' : 'Save Changes'}
                            </button>
                        </div>
                    </div>
                </div>}
            <div className={styles.main} style={{ backgroundColor: pageBColor }}>
                {vFormOpen && <VCard
                    content={<>
                        <b>Popuptitle</b>
                    </>}
                    value={[pageName, pageId, fullName, image, uid]}///////ioi
                    handleClose={openVForm}
                />}
                <div className={styles.pageName}>
                    /{id}
                </div>
                <div className={styles.topPage}>
                    <div className={styles.profilePicBorder}>
                        <div className={styles.profilePic}>
                            <img className={styles.image} alt=''
                                src={image} />
                        </div>
                    </div >
                    <label htmlFor="upload-picture">
                    </label>
                    <button
                        className={styles.addPic}
                        onClick={addPicInput}>
                        edit
                    </button>
                    <div className={styles.contactContainer}>
                        <button className={styles.vcfBtnUpload}
                            onClick={addContactInput}
                        //    onClick={uploadPicture}
                        >Upload Contact File</button>
                        <div className={styles.vcfBtns}
                            type='button'
                            onClick={openVForm}
                            value="Create Contact File"
                        >Create Contact File
                        </div>
                    </div>
                    <input
                        onChange={handleVCFChange}
                        ref={contactFileInput}
                        accept='.vcf'
                        type='file'
                        style={{ display: 'none' }}
                    >
                    </input>
                    <div className={styles.splitRow}>
                        <div className={styles.txt}>Allow Contact Download on Public Page: </div>
                        <ReactSwitch className={styles.switch} checked={checkedD} value={checkedD} onChange={handleCheckD}
                            onColor="#9a81ff"
                            checkedIcon={false}
                            uncheckedIcon={false}
                        />
                    </div>
                    <div className={styles.splitRow}>
                        <div className={styles.txt}>Allow Contact Exchange on Public Page: </div>
                        <ReactSwitch className={styles.switch} checked={checkedX} value={checkedX} onChange={handleCheckX}
                            onColor="#9a81ff"
                            checkedIcon={false}
                            uncheckedIcon={false}
                        />
                    </div>
                    <button className={styles.changeBtn}
                        onClick={toggleColorPicker}
                    > <div class={styles.changeColorTxt}>Change Page Color</div>
                        <Icon path={mdiFormatColorFill} size={1} className={styles.changeColorLogo} />
                    </button>
                    {colorPickerOpen &&
                        <ColorPicker
                            pageBColor={pageBColor}
                            setPageBColor={setPageBColor}
                            mode="pBColor"
                            handleClose={toggleColorPicker}
                        />
                    }

                    <div className={styles.infoInputsBox}>
                        <div className={styles.title}>
                            Details
                        </div>
                        <div className={styles.pageNameTitle}>
                            Name:
                        </div>
                        <input className={styles.txtInput}
                            value={fullName}
                            placeholder='Full Name'
                            onChange={e => setFullName(e.target.value)}
                            id="fullNameInput"
                        ></input>

                        <div className={styles.pageNameTitle}>
                            Headline:
                        </div>
                        <input className={styles.txtInput}
                            value={headline}
                            placeholder='Headline'
                            onChange={e => setHeadline(e.target.value)}
                            id="headlineInput"
                        ></input>


                        <div className={styles.pageNameTitle}>Biography:</div>
                        <textarea
                            className={styles.inputBio}
                            value={biography}
                            placeholder={'Biography...'}
                            onChange={handleBio}
                            rows={taHeight}

                        //  ref={textAreanRef}
                        >

                        </textarea>
                    </div>

                    <input
                        ////////////////////////////////////input pic fileflin
                        onChange={handlePicChange}
                        placeholder="test"
                        ref={hiddenFileInput}
                        accept="image/*"
                        style={{ display: 'none' }}
                        type='file'
                    >
                    </input>

                    <div className={styles.title}>
                        Social Icons
                    </div>

                    {userSocials &&

                        <div className={styles.draggableS}>
                            <Fragment>
                                <DragSocials userIconColor={userIconColor} setUserIconColor={setUserIconColor} iconBackgroundColor={iconBackroundColor} setIconBackgroundColor={setIconBackroundColor} userSocials={userSocials} setUserSocials={setUserSocials} />
                            </Fragment>
                        </div>
                    }


                    <div onChange={radioColor} className={styles.colorRadio} >

                        <input type='radio' value={color.purple} name='color' /> Purple
                        <input type='radio' value={color.black} name='color' /> Black
                        <input type='radio' value={color.white} name='color' /> White

                    </div>



                </div>

                <div className={styles.socialBox}>

                    <button className={styles.openSocialBox} se
                        onClick={toggleSocialBox}>
                        Add Social Icon
                        <Icon path={mdiPlus} size={1} />
                    </button>


                    {isOpen &&
                        <div className={styles.popBackground}>
                            <div className={styles.socialPop}>
                                <div className={styles.closeCont}> <Icon path={mdiClose} size={1} className={styles.closeSocialPop} onClick={toggleSocialBox} /></div>
                                <div className={styles.titleS}>Choose an Icon:</div>
                                <div className={styles.iconArray}>
                                    {mapTest?.map((l, index) => {
                                        return (
                                            <div key={index} className={styles.eachBtn}
                                            >
                                                <Icon path={l[1].logo}
                                                    className={styles.eachIcon}
                                                    size='3vh'
                                                    color={userIconColor}
                                                    style={{ backgroundColor: iconBackroundColor }}
                                                    value={l[0].name}
                                                    onClick={() => {
                                                        toggleSocialParams();
                                                        setCurrentSocialLink({ name: l[0].name, logo: l[1].logo });
                                                        // setCurrentSocialLink({...currentSocialLink, name: l[0].name})
                                                    }}
                                                />
                                            </div>

                                        );
                                    })}
                                </div>
                                <div className={styles.socialParams}>
                                    {socialParamsOpen && <SocialParamsPop1
                                        content={<>
                                            <b>SocialParamsPop</b>
                                        </>}
                                        handleClose={toggleSocialParams} />}
                                </div>
                            </div>
                        </div>
                    }

                </div>

                <div className={styles.title}>
                    Links
                </div>

                {isLoading ? (
                    <div><div>Loading</div></div>
                ) : (
                    <div className={styles.draggable}>

                        <Drag pageLinks={pageLinks} setPageLinks={setPageLinks} pageId={pageId} />

                    </div>
                )
                }

                <button onClick={toggleLinkParams} className={styles.openSocialBox}>Create a New Link <Icon path={mdiPlus} size={1} /></button>

                {linkCOpen &&
                    <div className={styles.popBackground}>
                        <div className={styles.linkInputs}>
                            <div className={styles.closeCont}> <Icon path={mdiClose} size={1} className={styles.closeSocialPop} onClick={toggleLinkParams} /></div>
                            <div className={styles.titleS}>Create a new Link:</div>
                            <div className={styles.linkInputBox}>
                                <div className={styles.pageNameTitle}>Link With Picture?</div>
                                <ReactSwitch
                                    checked={newLinkNeedsPic}
                                    value={newLinkNeedsPic}
                                    onChange={handleLinkPicSwitch}
                                    onColor="#9a81ff"
                                    offColor="#e6e6e6"
                                    checkedIcon={false}
                                    uncheckedIcon={false}

                                />

                                <div className={styles.pageNameTitle}>Link Title:</div>
                                <input
                                    className={styles.txtA}
                                    placeholder="Link Title"
                                    value={currentLink.name}
                                    onChange={e => setCurrentLink(
                                        { ...currentLink, name: e.target.value })}

                                />

                                <div className={styles.pageNameTitle}>Link Url:</div>
                                <input
                                    className={styles.txtA}
                                    placeholder="Link Url"
                                    value={currentLink.url}
                                    onChange={e => setCurrentLink({ ...currentLink, url: e.target.value })}

                                />
                                {newLinkNeedsPic &&
                                    <button
                                        className={styles.openSocialBox}
                                        onClick={addBtnPic}
                                    >
                                        Add Picture To Button</button>}

                                <input type="file"
                                    accept='image/*'
                                    onChange={(e) => setBtnImage(e.target.files[0])}
                                    ref={hiddenBtnPicInput}
                                    style={{ display: 'none' }} />

                                {btnImage && <AddBtnPic currentLink={currentLink} setCurrentLink={setCurrentLink} image={btnImage} setImage={setBtnImage} pageId={pageId} pageLinks={pageLinks} setPageLinks={setPageLinks}
                                />}
                            </div>


                            <button
                                onClick={() => {
                                    setPageLinks([...pageLinks, { name: currentLink.name, url: currentLink.url, id: currentLink.id, StorageSrc: `images/btnPic/${authUser?.uid}/${pageId}/${currentLink.id}` }]);
                                    setCurrentLink({ url: '', name: '', id: '' });
                                    toggleLinkParams();
                                }}
                                disabled={!currentLink.name || !currentLink.url || !validLURL}
                                className={styles.addSocialBtn}>Add Link
                            </button>
                        </div>
                    </div>
                }

                <div className={styles.submitCont}>
                    <button
                        onClick={!isEdit ? createPage : updatePage}//, uploadPicture
                        disabled={!pageName || !fullName.length}
                        className={styles.submit}>
                        {!isEdit ? 'Create Page' : 'Save Changes'}
                    </button>

                </div>






            </div>
        </div>
    );


};




