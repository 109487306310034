
import {useEffect, useState} from 'react';
import styles from './styles.module.css';
import { emailRegex } from '../../shared/constants';
import { fb } from '../../shared/service';
import { useHistory } from 'react-router-dom';
import { useSize } from '../../hooks/useSize';
import { NavBar } from '../NavBar';
import { uuid } from '../../shared';
//const [inviteId, setInviteId] = useState(null);
/* <input 
        type ='id' 
        value = {inviteId}
        placeholder={inviteId}
        onChange = {e => setInviteId(e.target.value)}
        />
        */
export const SignupTeam = () => {
    const [email, setEmail] = useState('');
    const [pw, setPW] = useState('');
    const [verifyPW, setVerifyPW] = useState(null);
    const [valid, setValid] = useState(false);
    const history = useHistory();
    const [error, setError] =useState('');
    const {size} = useSize();
   const companyId = uuid();
    useEffect(() => {
        setValid(
            email && emailRegex.test(email) && pw && verifyPW && pw === verifyPW);
    },[email, pw, verifyPW]);

    const signup = () => {
        if(valid) {
            fb
             .auth
             .createUserWithEmailAndPassword(email,pw)
             .then((userCredential) => {
                const user = userCredential.user;
                console.log('Signup Successfull!', user);
                if(user){
                    fb.firestore.collection('roles').doc(companyId).set({
                        [user.uid]: {
                            admin: true

                        }
                        
                    })
                }
             history.push('/create-team-details', {companyId: companyId});
             })
             .catch((error) => {
                if (
                    error.code ==='auth/email-already-in-use'
                ) {
                    setError('Email Already in Use')
                }
                if(
                    error.code ==='auth/weak-password'
                ) {
                    setError('Weak Password! Your password must be at least 6 characters.')
                }
                console.log('Code =>',error.code, 'Message => ',error.message)
               /* const code = error.code;
                if(code == '(auth/email-aready-in-use)'){
                    console.log('%!email already in use!%');
                }*/
             })
            
        }
    };



    const PwMsg = () => {

        if(verifyPW !== null || undefined){
            if(verifyPW === pw){
                return(
                    <div className={styles.PwMsg}>
                        Passwords Match!
                        </div>
                )
            }
            if(verifyPW !== pw){
                return(
                    <div className={styles.PwMsgBad}>
                       Your Passwords Do Not Match :(
                    </div>
                )
            }

        }

       /* return(
            <div></div>
        )*/

        return null;
            
        

        

        
    }

    return (
        <div className={styles.outside}>
            <NavBar value={size}/>
    <div className={styles.main}>
        <div className={styles.h1}>Team Sign Up</div>
        <div className={styles.subTxt}>
            Create an admin account for your team, business or enterprise
        </div>
        <div className={styles.p1}>email *</div>
        <input 
        type ='email' 
        value = {email}
        placeholder='your@email.com'
        onChange = {e => setEmail(e.target.value)}
        />
        <div className={styles.p1}>password *</div>
        <input 
        type ='password' 
        value = {pw}
        placeholder='password'
        onChange = {e => setPW(e.target.value)}
        />
        <div className={styles.p1}>confirm password *</div>
        <input 
        type ='password' 
        value = {verifyPW}
        placeholder='password'
        onChange = {e => setVerifyPW(e.target.value)}
        />
        <PwMsg/>
        {error && <div className={styles.error}>{error} </div>}
        <button onClick={signup} disabled={!valid}>Sign Up</button>
        

        </div>
        </div>
    );

};