//import { Autocomplete, Chip, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom"
import ReactSwitch from "react-switch";
import styles from './styles.module.css';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { fb } from '../../shared/service';
import { useAuth } from '../../hooks';


export const CreateTeamDetails = () => {
    const location = useLocation();
    const [checked, setChecked] = useState(false);
    const [name, setName] = useState()
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const history = useHistory();
    const {authUser} = useAuth();
    const handleCheck = (val) => {
        setChecked(val);
    };
    const options = [
        { label: 'Accounting', id: 1 },
        { label: 'Business Development', id: 2 },
        { label: 'Customer Service', id: 3 },
        { label: 'Engineering', id: 4 },
        { label: 'Finance', id: 5 },
        { label: 'Human Resources', id: 6 },
        { label: 'Information Technology', id: 7 },
        { label: 'Legal', id: 8 },
        { label: 'Marketing', id: 9 },
        { label: 'Operations', id: 10 },
        { label: 'Product Management', id: 11 },
        { label: 'Purchasing', id: 12 },
        { label: 'Quality Assurance', id: 13 },
        { label: 'Research and Development', id: 14 },
        { label: 'Sales', id: 15 },
        { label: 'Supply Chain', id: 16 },
        { label: 'Training and Development', id: 17 },
        { label: 'Account Management', id: 18 },
        { label: 'Advertising', id: 19 },
        { label: 'Analytics', id: 20 },
        { label: 'Art and Design', id: 21 },
        { label: 'Brand Management', id: 22 },
        { label: 'Community Management', id: 23 },
        { label: 'Consulting', id: 24 },
        { label: 'Content', id: 25 },
        { label: 'Creative', id: 26 },
        { label: 'Data Science', id: 27 },
        { label: 'Digital Marketing', id: 28 },
        { label: 'E-commerce', id: 29 },
        { label: 'Editorial', id: 30 },
        { label: 'Education and Training', id: 31 },
        { label: 'Event Planning', id: 32 },
        { label: 'Facilities', id: 33 },
        { label: 'Food and Beverage', id: 34 },
        { label: 'Healthcare Services', id: 35 },
        { label: 'Hospitality', id: 36 },
        { label: 'Insurance', id: 37 },
        { label: 'Investment Banking', id: 38 },
        { label: 'Logistics', id: 39 },
        { label: 'Manufacturing', id: 40 },
        { label: 'Media Planning', id: 41 },
        { label: 'Merchandising', id: 42 },
        { label: 'Nonprofit and Social Services', id: 43 },
        { label: 'Photography', id: 44 },
        { label: 'Public Relations', id: 45 },
        { label: 'Real Estate', id: 46 },
        { label: 'Recruiting', id: 47 },
        { label: 'Research', id: 48 },
        { label: 'Sales Support', id: 49 },
        { label: 'Security', id: 50 },
    ];

    const handleDepartmentSelection = (event, value) => {
        setSelectedDepartments(
            value.map((label) => ({
                label,
                info: {},
                users: []       
           
            }))
        );
    }

    useEffect(() => {
        console.log('selected departments', selectedDepartments);
    }, [selectedDepartments]);

    const enterTeamDetails = () => {
        if(selectedDepartments){
            fb.firestore.collection('companyInfo').doc(location?.state?.companyId).set({
            name: name,
            admin: [authUser.uid],
            departments: selectedDepartments
        },{merge: true});

        }
        
        history.push('/create-team-page', {companyId: location?.state?.companyId, teamName: name, department: selectedDepartments})

    }






    const Departments = () => {

        return (
            <div>
                <Stack sx={{ width: 340 }}>
                    <Autocomplete
                        multiple
                        disableClearable
                        id="departments"
                        freeSolo
                        options={options.map((option) => option.label)}
                        value={selectedDepartments.map((department) => department.label)}
                        onChange={handleDepartmentSelection}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Enter your Departments"
                                placeholder='Add a Department'
                            />
                        )}
                    />
                </Stack>

            </div>
        )
    }

    return (
        <div>

            <div className={styles.outside}>
               
                <div className={styles.main}>
                    <div className={styles.h1}>Enter Team Info</div>
                    <div className={styles.p1}>company name</div>
                    <input
                        type='company'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='company name'
                        autoComplete="company"

                    />
                    <div className={styles.splitRow}>
                        <div className={styles.txt}
                        >Will you require multiple departments for card holders?</div>
                        <ReactSwitch
                            checked={checked} onChange={handleCheck}
                            onColor="#9a81ff"
                            offColor="#e6e6e6"
                            checkedIcon={false}
                            uncheckedIcon={false}
                        />
                    </div>

                    {checked && <Departments />}

                    <button onClick={enterTeamDetails}
                        disabled={!name}
                    >Sign Up</button>


                </div>
            </div>

        </div>
    )

}