import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import './phone.css';
import { emailRegex, phoneRegex, uuid } from "../../shared";
import styles from "./styles.module.css";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useHistory } from "react-router-dom";
import { fb } from "../../shared/service";
import { MdNorthWest } from "react-icons/md";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import axios from "axios";

export const XChange = props => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const history = useHistory();
    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);

    const handleCheckOne = () => {
        setCheckOne(!checkOne);
    };
    const handleCheckTwo = () => {
        setCheckTwo(!checkTwo);
    };

    const userId = props.value[0].userId;

    const [valid, setValid] = useState(false);

    useEffect(() => {
        setValid(
            //email only
            firstName && checkOne === true && checkTwo === true && email && emailRegex.test(email) && phoneNumber === ''
            ||
            //phone only
            firstName && checkOne === true && checkTwo === true && phoneNumber && phoneRegex.test(phoneNumber) && email === ''
            ||
            //both email & phone
            firstName && checkOne === true && checkTwo === true && phoneNumber && phoneRegex.test(phoneNumber) && emailRegex.test(email)
        );
    }, [firstName, phoneNumber, email, checkOne, checkTwo]);


    const handleSubmit = (e) => {
        e.preventDefault();
        function close() {
            props.handleClose();
        }
        const createDate = Timestamp.fromDate(new Date());
        const mils = createDate.toMillis();
        console.log('createDate', createDate, 'mils', createDate.toMillis());

        const data = {
            userId: userId, // Replace with the user's ID
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            createDate: createDate,
            mils: mils
        }
        axios.post('https://us-central1-yo-aristides.cloudfunctions.net/addContactField', data)
            .then(response => {
                console.log(response.data)
            }).catch(error => {
                console.error('error calling function', error)
            });
        close();
    }

    return (
        <div className={styles.main}>
            <span className={styles.closeIcon} onClick={props.handleClose}>
                <Icon path={mdiClose} size="4rem" />
            </span>
            <div className={styles.profilePicBorder}>
                <div className={styles.profilePic}>
                    <img className={styles.image}
                        src={props.value[1]} />
                </div>
            </div >
            <form className={styles.form}>

                <div className={styles.connect}>{"Connect with " + props.value[0].userFullName}</div>
                <div className={styles.enter}>Enter your contact information below.</div>

                <input
                    className={styles.input}
                    id='firstName'
                    type="text"
                    maxLength="20"
                    placeholder='First Name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value.trim())}
                />
                <input
                    className={styles.input}
                    id='lastName'
                    type="text"
                    maxLength="25"
                    placeholder='Last Name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value.trim())}
                />

                <PhoneInput
                    defaultCountry={'us'}
                    country={'us'}
                    specialLabel={""}
                    className={styles.PhoneInput}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    component={TextField}
                    inputExtraProps={{
                        margin: 'normal',
                        autoComplete: 'phone',
                        border: 'none',
                    }}
                />

                <div className={styles.andOr}>And/Or</div>

                <input
                    className={styles.input}
                    id='work'
                    type="text"
                    maxLength="40"
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <div className={styles.agreeContainer}>
                    <div className={styles.checkCont}>
                        <input type='checkbox' className={styles.checkBx} checked={checkOne} onChange={handleCheckOne}>
                        </input>
                    </div>
                    <div className={styles.agreeTxt}>
                        I agree to yoitsme's <span className={styles.underline} onClick={() => history.push('/terms-and-conditions')}>Terms & Conditions</span> and <span className={styles.underline} onClick={() => history.push('/privacy-policy')}>Privacy Policy</span>.
                    </div>
                </div>
                <div className={styles.agreeContainer}>
                    <div className={styles.checkCont}>
                        <input type='checkbox' className={styles.checkBx} checked={checkTwo} onChange={handleCheckTwo}>
                        </input>
                    </div>

                    <div className={styles.agreeTxt}>
                        I agree to my contact information being shared with {props.value[0].userFullName}, who may contact me.
                    </div>

                </div>

                <button className={styles.xConBtn} disabled={!valid}
                    onClick={handleSubmit}
                >Exchange Contact</button>

            </form>
        </div>
    );
};