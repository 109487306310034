import { async } from '@firebase/util';
import { mdiDownload, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { deleteField, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getMetadata, list, listAll, ref } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth, usePages } from '../../hooks';
import { fb } from '../../shared/service';
import { AuthedNav } from '../AuthedNav';
import styles from './styles.module.css';



export const MyContacts = props => {
    const { userId } = props?.location?.state?.userId;
    const history = useHistory();
    const pageInfo = props.location.state;
    const { id } = useParams();
    const storage = fb.storage;
    /////////////////////////////
    const [contacts, setContacts] = useState(null);
    const [contactsGroupByD, setContactsGroupByD] = useState(null)
    const [lastContactTimestamp, setLastContactTimestamp] = useState();
    const [userContactsRef, setUserContactsRef] = useState(null);
    const [sortingBRecent, setSortingByRecent] = useState(true);
    const [contactViewOpen, setContactViewOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState();
    const [image, setImage] = useState();
    const [vcf, setVcf] = useState(null);
    const [refresh, setRefresh] = useState(0);
    useEffect(() => {
        console.log('userId', userId, 'id', id, 'userContRef', userContactsRef)

    }, [userContactsRef, userId])


    //const createDate = admin.firestore.Timestamp.now();
    useEffect(() => {
        if (userContactsRef !== null || undefined) {
            userContactsRef.get().then((doc) => {
                if (doc.exists) {
                    const userData = doc.data();
                    const userContacts = Object.values(userData);
                    // setContacts(Object.values(userData));

                    // Sort the contacts by timestamp (if timestamp field exists)
                    userContacts.sort((b, a) => {
                        return (a.cDate || 0) - (b.cDate || 0);
                    });

                    // Paginate by slicing the array
                    let paginatedContacts = userContacts.slice(0, 20);

                    // Update state with the new contacts
                    setContacts(paginatedContacts);

                    // Format the cDate field as "01/01/99" and group the array by date
                    const groupedContacts = userContacts.reduce((result, contact) => {
                        const date = new Date(contact.cDate.toDate());
                        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)}`;

                        if (!result[formattedDate]) {
                            result[formattedDate] = [];
                        }
                        result[formattedDate].push(contact);
                        return result;
                    }, {});

                    // Sort the grouped contacts by date
                    const sortedGroupedContacts = Object.entries(groupedContacts)
                        .sort((b, a) => {
                            const dateA = new Date(a[0]);
                            const dateB = new Date(b[0]);
                            return dateA - dateB;
                        });

                    // Extract the contacts from the sorted grouped contacts
                    const contactsArray = sortedGroupedContacts.map(([date, contacts]) => ({ date, contacts }));

                    // Update state with the new contacts
                    setContactsGroupByD(contactsArray);

                    // Store the last contact's timestamp for the next pagination
                    if (contactsArray.length > 0) {
                        setLastContactTimestamp(contactsArray[contactsArray.length - 1].contacts[0].cDate);
                    }
                }
            });
        }

    }, [userContactsRef, refresh]);///////////////////////////

    useEffect(() => {
        if (contacts) {
            console.log('CONTACTS = ', contacts, 'LastTimeStamp = ', lastContactTimestamp, "groupedContactsby D =>", contactsGroupByD)
        }
    }, [contacts, contactsGroupByD]);

    useEffect(() => {//once VCF is set this will trigger and download file for client
        if (vcf !== null || undefined) {
            console.log('VCF = ', vcf);
            const fileName = `${selectedContact?.fName}_${selectedContact?.lName}.vcf`
            const url = URL.createObjectURL(vcf);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

            console.log('Blob URL =>', url)

        }
    }, [vcf])




    const [allContacts, setAllContacts] = useState(undefined);

    const [firstRender, setFirstRender] = useState(false);

    useEffect(() => {
        if (userId !== null || undefined) {
            console.log('props', props.location.state.userId, '?', userId);
            setUserContactsRef(fb.firestore.collection("sharedContacts").doc(props.location.state.userId));
            setFirstRender();

        }

    }, [userId]);

    const listRef = ref(storage, `sharedContacts/${props?.location?.state?.userId}/${id}/`);



    useEffect(() => {

        if (allContacts == undefined) {
            list();
            setFirstRender(true);


            //   console.log('!-!ran Useffect for list()');
        }

    }, [allContacts]);

    async function list() {//lists all vcf contact files from fb.storage (items and prefixes) that have shared with them 
        if (firstRender == false) {
            listAll(listRef)
                .then((res) => {

                    res?.items.forEach((itemRef) => {

                        //  allContacts.push(itemRef);
                        setAllContacts(res.items);
                        //console.log('all contacts res', res.items);
                    });
                }).catch((error) => {
                    console.log(error);

                });

        }

    }
    const downloadContact = (l) => {
        console.log('downloadContact.fx "l" => ', l)
        const createVcf = () => {
            var VCF = new Blob(
                [`
BEGIN:VCARD
VERSION:3.0
N:${l?.lName || ''};${l?.fName || ''};;;
FN:${l?.fName || ''} ${l?.lName || ''}
ORG:${l?.org || ''}
EMAIL;type=INTERNET;type=HOME;:${l?.email || ''}
EMAIL;type=INTERNET;type=WORK;:${l?.wEmail || ''}
TEL;type=CELL:${l?.mPhone || ''}
TEL;type=WORK:${l?.wPhone || ''}
ADR;CHARSET=UTF-8; TYPE=WORK:;;${l?.wAddress || ''}
TITLE;CHARSET=UTF-8:${l?.title || ''}
URL;type=WORK;CHARSET=UTF-8:${l?.website || ''}
PHOTO;ENCODING=b;TYPE=JPEG:/9j/${image || ''}        
END:VCARD`
                ],
                { type: "text/vcard" }
            )
            setVcf(VCF)
            // console.log('VCF BLOB', VCF)
        };
        createVcf()
    }

    const dlContact = (l) => {

        if (window.confirm('Are you sure you want to delete this contact?')) {
            const deleteRef = fb.firestore.collection('sharedContacts').doc(props.location.state.userId)
            console.log('dl fx to find field name', l.cDate.toMillis(), 'userContactRef', userContactsRef)
            const objectIdToDelete = l.cDate.toMillis();
            deleteRef.get().then((doc) => {
                if (doc.exists) {
                    /* updateDoc(userContactsRef, {
                         objectIdToDelete: deleteField()
                     })*/
                    var removeFile = deleteRef.update({
                        [objectIdToDelete]: deleteField()
                    });
                    setContactViewOpen(false);

                    setRefresh(refresh + 1)
                    // const userData = doc.data();
                    // const userContacts = userData || []; // Assuming "contacts" is the array

                    // Filter out the object with the specified ID
                    // const updatedContacts = userContacts.filter(contact => contact.id !== objectIdToDelete);

                    // Update the document with the modified array
                    // userContactsRef.update({ updatedContacts });
                }
            });
        }
    }

    const [searchQuery, setSearchQuery] = useState('');

    const GroupedByDay = ({ contactsGroupByD }) => {
        const filteredContactsGroupByD = contactsGroupByD
            .map(group => ({
                ...group,
                contacts: group.contacts.filter(contact =>
                    contact.fName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    contact.lName.toLowerCase().includes(searchQuery.toLowerCase())
                ),
            }))
            .filter(group => group.contacts.length > 0);
        return (
            <div>
                {filteredContactsGroupByD?.map((l, index) => {
                    return (
                        <div className={styles.groupedDateCont} key={index}>
                            <div className={styles.groupedDateStr}>
                                {l.date}
                            </div>
                            {l?.contacts?.map((l, index) => {
                                return (
                                    <div className={styles.contactContainer} key={index}
                                        onClick={() => {
                                            setSelectedContact(l);
                                            setContactViewOpen(true)
                                        }}
                                    >
                                        <div className={styles.contactName}
                                        >{l.fName} {l.lName}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )

                })}
            </div>
        )
    }

    const MostRecent = ({ contacts }) => {//Actually Alfebatizes List


        // Filter the contacts based on the search query
        const filteredContacts = contacts.filter(contact =>
            contact.fName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            contact.lName.toLowerCase().includes(searchQuery.toLowerCase())
        );

        return (
            <div>
                {filteredContacts?.map((l, index) => {
                    return (
                        <div className={styles.contactContainer}
                            key={index}
                            style={{ backgroundColor: index % 2 === 0 ? '#F6F6F6' : '#FFFFFF' }}
                            onClick={() => {
                                setSelectedContact(l);
                                setContactViewOpen(true)
                            }} >
                            <div className={styles.contactName}>
                                {l.fName} {l.lName}
                            </div>
                            <div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )

    }

    const ViewContact = () => {//Pop up Contact Info Container pulls from selectedContact (set onClick)
        //{l.cDate.toMillis()}      
        console.log('Selected Contact From ViewContact =>', selectedContact)
        return (
            <div className={styles.cInfoPop}
            >
                <div className={styles.navBtnRow}>
                    <div className={styles.leftBtnCont}>
                        <button
                            onClick={() => {
                                // history.push('/')
                                setContactViewOpen(false);
                            }}
                            className={styles.navBtn}>Back</button>
                    </div>


                    <button
                        // style={{ color: sortingBRecent ? '#007AFF' : '#363636' }}
                        onClick={() => {
                            //contactViewOpen(false);
                            downloadContact(selectedContact);
                        }}
                        className={styles.dBtn}>Download</button>

                </div>
                <div className={styles.selectedName}>
                    {selectedContact?.fName} {selectedContact?.lName}
                </div>



                <div className={styles.cInfoCont}>
                    <div className={styles.cInfoRow}>
                        <div className={styles.cInfoLabel}>Phone</div>
                        <div className={styles.cInfoTxt}>{selectedContact?.mPhone}</div>
                    </div>
                    <svg width="100%" maxwidth="333px !important" height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1H338" stroke="#A1A1B0" strokeLinecap="round" />
                    </svg>
                    <div className={styles.cInfoRow}>
                        <div className={styles.cInfoLabel}>Email</div>
                        <div className={styles.cInfoTxt}>{selectedContact?.email}</div>
                    </div>
                    <svg width="100%" maxwidth="333px !important" height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1H338" stroke="#A1A1B0" strokeLinecap="round" />
                    </svg>
                    <div className={styles.cInfoRowA}>
                        <div className={styles.cInfoLabel}>Address</div>
                        <div className={styles.cInfoTxtBX}>{selectedContact?.address}</div>
                    </div>
                </div>

                <div className={styles.cInfoCont}>
                    <div className={styles.cInfoRow}>
                        <div className={styles.cInfoLabel}>Notes</div>
                        <div className={styles.cInfoTxt}>Coming Soon</div>
                    </div>
                    <svg width="100%" maxwidth="333px !important" height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1H338" stroke="#A1A1B0" strokeLinecap="round" />
                    </svg>
                </div>
                <Icon path={mdiTrashCan} className={styles.deleteLogo}
                    onClick={() => dlContact(selectedContact)}
                />
            </div>
        )
    }




    return (//#007AFF
        <div className={styles.main}>
            {contactViewOpen && <ViewContact />}
            <div className={styles.navBtnRow}>
                <div className={styles.leftBtnCont}>
                    <button
                        onClick={() => { history.push('/') }}
                        className={styles.navBtn}>Back
                    </button>
                </div>
                <div className={styles.rightBtnGroup}>
                    <button
                        style={{ color: !sortingBRecent ? '#007AFF' : '#363636' }}
                        onClick={() => { setSortingByRecent(false) }}
                        className={styles.navBtn}>A-Z
                    </button>
                    <button
                        style={{ color: sortingBRecent ? '#007AFF' : '#363636' }}
                        onClick={() => { setSortingByRecent(true) }}
                        className={styles.navBtn}>Recent
                    </button>
                </div>
            </div>
            <input
                className={styles.searchBox}
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />

            {firstRender === true ? (
                <div>
                    <div className={styles.title}></div>
                    <div className={styles.columnContainer}>
                        <div className={styles.column}>
                            {!sortingBRecent && contacts && <MostRecent contacts={contacts} />}
                            {sortingBRecent && contactsGroupByD && <GroupedByDay contactsGroupByD={contactsGroupByD} />}
                        </div>
                    </div></div>
            ) : (
                <div className={styles.title}>You dont have any contacts yet :(</div>
            )}




        </div>
    );

};