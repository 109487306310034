import styles from './styles.module.css';
import { AuthedNav } from "../AuthedNav"
import { useState } from 'react';
import { FilterDepartment } from '../DashboardAdmin/FilterDepartment';
import { useAuth } from '../../hooks';
import { fb } from '../../shared/service';
import { emailRegex, uuid } from '../../shared';
import { GetCompanyInfo } from '../DashboardAdmin/GetCompanyInfo';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
//import { sendSignInLinkToEmail } from 'firebase/auth'
import firebase from 'firebase/compat/app';
import 'firebase/functions'
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Loading } from '../Loading/Loading';
//import { getAuth } from 'firebase/auth';
//import {firebase }from 'firebase';
export const CreateEmployeePage = () => {
    const functions = getFunctions();
    const { authUser } = useAuth();
    const { companyId } = GetCompanyInfo();
    const [fullName, setFullName] = useState('');
    const [pageName, setPageName] = useState('');
    const [email, setEmail] = useState('');
    const [department, setDepartment] = useState('');
    const [id, setId] = useState();
    const [newId, setNewId] = useState();
    const location = useLocation();
    const history = useHistory();
    const [error, setError] = useState();
    const [employee, setEmployee] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        setId(uuid());
        setNewId(uuid());
    }, []);

    useEffect(() => {
        setValid(pageName && fullName && email && emailRegex.test(email));
    }, [email, pageName, fullName]);

    useEffect(() => {
        if (id && newId) {
            console.log('id / employee uid', id, 'newId / pageId', newId);
        }
    }, [id, newId])

    const actionCodeSettings = {
        url: `${window.location.origin}/employee-signin/${companyId}/${department}/${email}/${fullName}`,
        handleCodeInApp: true,
    }

    async function addEmailToDepartment(companyId, departmentLabel, userEmail) {
        const companyInfoDocRef = doc(fb?.firestore, "companyInfo", companyId);
        const companyInfoDoc = await getDoc(companyInfoDocRef);
        if (companyInfoDoc.exists()) {
            const companyInfoData = companyInfoDoc.data();
            const departments = companyInfoData.departments;
            const department = departments.find((d) => d.label === departmentLabel);

            if (department) {
                const newDepartmentUsers = [...department.users, { email: userEmail, uid: id }];
                const departmentIndex = departments.findIndex((d) => d.label === departmentLabel);
                const updatedDepartments = [...departments];
                updatedDepartments[departmentIndex] = {
                    ...department,
                    users: newDepartmentUsers,
                };
                await updateDoc(companyInfoDocRef, {
                    departments: updatedDepartments,
                });
                console.log(`Added ${userEmail} to department ${departmentLabel}`);
            } else {
                console.log(`Department ${departmentLabel} not found`);
            }
        } else {
            console.log(`Company with ID ${companyId} not found`);
        }
    }
    /*
        const sendEmployeeInvite = () => {
            console.log('send employee runing p1')
            fb?.auth?.sendSignInLinkToEmail(email, actionCodeSettings)
                .then(() => {
                    // The link was successfully sent. Inform the user.
                    // Save the email locally so you don't need to ask the user for it again
                    // if they open the link on the same device.
                    addEmailToDepartment(companyId, department, email)
                    console.log('send employee runing p2')
                    // window.localStorage.setItem('emailForSignIn', email);
                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ...
                })
        }*/
    const sendRequest = async () => {
        //setLoading(true);
        console.log('running Send Request');

        const docRef = doc(fb.firestore.collection('linkPages'), pageName);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setError(`The page name ${pageName} Has Already Been Taken :(`);
            setIsLoading(false);
        } else {
            if (authUser && pageName) {//upload employee page
                fb?.firestore?.collection('linkPages').doc(pageName.toLowerCase()).set({
                    userId: id,
                    name: pageName.toLowerCase(),
                    pageId: newId,
                    userFullName: fullName,
                    links: [],
                    socials: [],
                    iconColor: '',
                    pageSelected: false,
                    showContactD: false,
                    showContactX: false,
                    companyId: companyId,
                    department: department,
                    // ...(isAdmin ? {isAdmin} : true)
                    isAdmin: false,

                }).then(async () => {
                    fb?.firestore?.collection('analytics').doc(companyId).collection(newId).doc('visitors').set({
                        btnClicks: [],
                        pageVisits: []
                    }).then(() => {
                        addEmailToDepartment(companyId, department, email)
                        history.push(`/admin/dashboard`);
                    })
                });

            }
        }
    }

    const createEmployee = () => {
        setIsLoading(true);
        //  const uid = uuid();
        var data = {
            "uid": id,
            "email": email,
            "password": "randomPW",
            "displayName": fullName,
            "disabled": false,
            "role": "member"
        };
        const addEmployee = httpsCallable(functions, 'createUser');
        addEmployee(data).then((result) => {
            const emp = result.data;
            console.log('emp', emp, result.data)
            console.log('res', result);
            //sendRequest();
            setEmployee(result);

        })
    }
    useEffect(() => {
        console.log("EMPLOYEE", employee);
        if (employee !== undefined) {
            sendRequest();
        }
    }, [employee]);
    // {isLoading && <Loading/>}
    return (
        <div className={styles.main2}>
            <AuthedNav />
            <div className={styles.main}>
                <div className={styles.infoInputsBox}>
                    <div className={styles.title}>
                        Create team member:
                    </div>
                    <div className={styles.pageNameTitle}>
                        Employee Name:
                    </div>
                    <input className={styles.txtA}
                        value={fullName}
                        placeholder='Full Name'
                        onChange={e => setFullName(e.target.value)}
                        id="fullNameInput"
                    ></input><div className={styles.pageNameTitle}>
                        Page Name (End of Url):
                    </div>
                    <div className={styles.splitRow2}>
                        <div className={styles.marginRight}>yoits.me/</div>
                        <input className={styles.txtA}
                            value={pageName}
                            placeholder='Page Name'
                            onChange={e => setPageName(e.target.value.toLowerCase().trim())}
                            id="pageNameInput"
                        ></input>
                    </div>
                    <div className={styles.pageNameTitle}>
                        Email:
                    </div>
                    <input className={styles.txtA}
                        value={email}
                        placeholder='Email'
                        onChange={e => setEmail(e.target.value)}
                        id="emailInput"
                    ></input>
                </div>
                <div className={styles.center}>
                    <FilterDepartment department={department} setDepartment={setDepartment} />
                </div>
                {error &&
                    <div className={styles.error}>
                        {error}
                    </div>
                }
                <button className={styles.submit} style={{ width: "200px" }}
                    onClick={createEmployee}
                    disabled={!valid}
                >Create Employee</button>
                {isLoading && <Loading />}

            </div>
        </div>
    )
}