import { usePages, useAuth } from "../../hooks";
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import { PagesLink } from "../PagesLink/PagesLink";
import { fb } from "../../shared/service";
import { AuthedNav } from "../AuthedNav";

export const Pages = () => {
    const { authUser } = useAuth();
    const pages = usePages(authUser?.uid);
    const history = useHistory();

    
    return (
        <div className={styles.main2}>
        <AuthedNav/>
        <div className={styles.main}>
           
            
            <h1 className={styles.title}>Dashboard</h1>
            <h2 className={styles.title}>Pages:</h2>

            <div
                onClick={() => history.push('/create-page-details')}
                className={styles.createPageBtn}>
                Create New Page
            </div>

            {pages?.map((p, index) => {
                return <PagesLink className={styles.page} key={index} page={p} />;
            })}
        </div>
        </div>
    );
};

