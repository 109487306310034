import { mdiConsoleLine } from "@mdi/js";

import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useEffect, useRef, useState } from "react"
import { useAuth } from "../../hooks";
import imageCompression from 'browser-image-compression';


export const AddBtnPic = ({ currentLink, setCurrentLink, image, setImage, pageId, pageLinks, setPageLinks }) => {
    const { authUser } = useAuth();
    const [imgUrl, setImgUrl] = useState();
    //  const imgUrl = URL.createObjectURL(image)
    console.log('AddBtnPic.js', currentLink, setCurrentLink, image, setImage, pageId, pageLinks, setPageLinks)
   
    const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 500,
        useWebWorker: false,


        //s  onProgress: () => {},
    };

    



    const uploadPicture = () => {
        const storage = getStorage();
        fetch(imgUrl)
            .then(function (res) {
                return res.blob()
            })
            .then(function (blob) {
                const storageRef = ref(storage, `images/btnPic/${authUser?.uid}/${pageId}/${currentLink.id}`)
                uploadBytes(storageRef, blob).then((snapshot) => {
                    console.log('uploaded')
                    setCurrentLink({...currentLink, StorageSrc: `images/btnPic/${authUser?.uid}/${pageId}/${currentLink.id}`})
                    setImage(null)
                });
            });
    }

    const compress = async () => {
        //event?.preventDefault();
        if (!image) return;
        try {
            const compressedImage = await imageCompression(image, options);
            // Upload compressedImage instead of selectedImage
            const compressedImgUrl = await URL.createObjectURL(compressedImage);
            setImgUrl(compressedImgUrl);
            console.log('compress url', compressedImage)

            // ...
        } catch (error) {
            console.error("Failed to compress image:", error);
        }
    }


    useEffect(() => {
        if (authUser) {
            console.log('AddBtnPic.js => ', currentLink, image, 'pageId', pageId)

            compress();
            //console.log('image Url', imgUrl)

        }

    }, [authUser]);
    useEffect(() => {
        console.log('imgURl USEEFECT=>', imgUrl)
        if (imgUrl !== null || undefined) {
            console.log('imgUrl before upload pic', imgUrl)
            
            uploadPicture();
        }
    }, [imgUrl])

    useEffect(() => {
        if (pageLinks) {
            console.log('pageLinks', pageLinks)
        }
    }, [pageLinks])






    return null;

}