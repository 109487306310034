import styles from "./styles.module.css";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React from "react";
import { fb } from "../../shared/service";
import { XChange } from "../XChange/XChange";
import { useState } from "react";
import { useEffect } from "react";
import { onSnapshot } from "firebase/firestore";

export const PageContact = props => {
    const page1 = props.value[0];
    const image = props.value[1];
    //const txtColor = props.value[2];
    const [contactData, setContactData] = useState(null);
    const [vcf, setVcf] = useState(null);

    // console.log('Props from page contact: ', props)

    useEffect(() => {
        if (contactData && vcf !== null || undefined) {
            const fileName = `${contactData?.fName}_${contactData?.lName}.vcf`
            const url = URL.createObjectURL(vcf);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

            console.log('Blob URL =>', url)
        }
    }, [contactData, vcf]);


    useEffect(() => {
        if (contactData !== null || undefined) {
            const createVCF = () => {
                var VCF = new Blob(
                    [`
BEGIN:VCARD
VERSION:3.0
N:${contactData?.lName || ''};${contactData?.fName || ''};;;
FN:${contactData?.fName || ''} ${contactData?.lName || ''}
ORG:${contactData?.org || ''}
EMAIL;type=INTERNET;type=HOME;:${contactData?.email || ''}
EMAIL;type=INTERNET;type=WORK;:${contactData?.wEmail || ''}
TEL;type=CELL:${contactData?.mPhone || ''}
TEL;type=WORK:${contactData?.wPhone || ''}
ADR;CHARSET=UTF-8; TYPE=WORK:;;${contactData?.wAddress || ''}
TITLE;CHARSET=UTF-8:${contactData?.title || ''}
URL;type=WORK;CHARSET=UTF-8:${contactData?.website || ''}
PHOTO;ENCODING=b;TYPE=JPEG:/9j/${image || ''}        
END:VCARD`
                    ],
                    { type: "text/vcard" }
                )
                setVcf(VCF)
                // console.log('VCF BLOB', VCF)
            };

            createVCF();
        }
    }, [contactData]);

    const downloadContact = () => {
        const storage = getStorage();
    
        const fetchContact = async () => {
            try {
                const url = await getDownloadURL(ref(storage, `contacts/${page1.userId}/${page1.pageId}/${page1.userFullName}.vcf`));
                // Fetch the vCard file using Fetch API
                const response = await fetch(url);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
    
                const blob = await response.blob();
                // You can now do something with the blob, like saving the file or downloading it
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${page1.userFullName}.vcf`;
                link.click();
                
            } catch (storageError) {
                console.log('File not found in Firebase Storage', storageError);
                
                try {
                    const docRef = fb.firestore.collection('userContacts').doc(page1.userId);
                    const docSnap = await docRef.get();
                    if (docSnap.exists) {
                        setContactData(docSnap.data());
                    } else {
                        console.log('No such document in Firestore');
                    }
                } catch (firestoreError) {
                    console.error('Error fetching document from Firestore:', firestoreError);
                }
            }
        };
    
        fetchContact();
    };
    



    const [xChangeOpen, setXChangeOpen] = useState(false);
    const toggleXCon = () => {
        setXChangeOpen(!xChangeOpen);
    };

    return (
        <div className={styles.contactRow}>

            {xChangeOpen && <React.Fragment>
                <div className={styles.xchangeBackground}
                >
                    <XChange handleClose={toggleXCon} value={[page1, image]} />
                </div>
            </React.Fragment>}

            {page1?.showContactD === true &&
                <div className={styles.contactInfo} style={{ backgroundColor: 'white', color: "#2FC154" }}
                    onClick={downloadContact}
                >
                    Contact Info
                </div>
            }
            {page1?.showContactX === true &&
                <div className={styles.xConBtn} style={{ backgroundColor: 'white', color: "#007AFF" }}
                    onClick={toggleXCon}>
                    Exchange Contact
                </div>
            }



        </div>
    )
}