import { useState } from "react";
import { useEffect } from "react";
import { uuid } from "../shared";
import { useAuth } from "./useAuth";



export const useVisitorId = () => {
    const [visitorId, setVisitorId] = useState(null);
    const {authUser} = useAuth();
   // const id = authUser?.uid;
   const [id, setId] = useState(null);

 
    useEffect(() => {
      if (authUser != null || undefined){
        setId(authUser?.uid);

      };
      
      

    },[authUser]);

    useEffect(() => {
      if (id !== null || undefined){
        console.log('auth user from newvisitor id', id);
      };     
    },[id]);

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
          return parts.pop().split(';').shift();
        }
      }
      
      function setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
      }




    useEffect(() => {
        const existingVisitorId = getCookie('visitorId');
          if(id != null || undefined){
          const newVisitorID = id;

          setCookie('visitorId', newVisitorID, 7);//cookie will persist on client for 7 days
            setVisitorId(newVisitorID);

        } else if(existingVisitorId){
          setVisitorId(existingVisitorId);

      }
        else {
            const newVisitorID = uuid();
            setCookie('visitorId', newVisitorID, 7);//cookie will persist on client for 7 days
            setVisitorId(newVisitorID);
        }
    },[id]);


    useEffect(() => {
      if(visitorId){
        console.log('visitor ID =?!!', visitorId)

      }
     
    },[visitorId])

    return{visitorId};
}