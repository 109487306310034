import styles from "./styles.module.css";
import { fb } from "../../shared/service";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import yologoicon from '../../images/yologoicon.ico';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import Icon from "@mdi/react";
import { uuid } from "../../shared";
import { useAuth } from "../../hooks";
import { AuthedNav } from "../AuthedNav";
import { height, width } from "@mui/system";
import { PageContact } from "../PageContact/PageContact";
import { useVisitorId, visitorId } from "../../hooks/useVisitorId";

import firebase from "firebase/app";
import "firebase/firestore";
import { arrayUnion, doc, Timestamp } from "firebase/firestore";
import { useDebugValue } from "react";
import assert from "assert";
import { userInfo } from "os";


export const PublicPage = data => {
  const history = useHistory();
  const [page1, setPage1] = useState();
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const [VCF, setVCF] = useState(null);
  const [page, setPage] = useState([]);
  const [visitorInfo, setVisitorInfo] = useState(null);
  const [noVcf, setNoVcf] = useState(false);
  const { isAuthed } = useAuth();
  const timeOfVisit = new Date().toISOString();
  const [isCompany, setIsCompany] = useState(false);
  const { visitorId } = useVisitorId();
  const [departmentInfo, setDepartmentInfo] = useState();
  /////////////////////////////////////////page params
  const [fullName, setFullName] = useState('');
  const [headline, setHeadline] = useState('');
  const [biography, setBiography] = useState('');
  const [links, setLinks] = useState(undefined);
  const [socials, setSocials] = useState([]);
  const [updatedLinks, setUpdatedLinks] = useState(null);
  const [pageBColor, setPageBColor] = useState();
  /*useEffect(() => {
    console.log('cookie =>', visitorId)
  }, [visitorId]);*/
  const [iconColor, setIconColor] = useState('');

  var referrer = document.referrer;
  let time = Date();
  const [time2, setTime2] = useState();
  let location = useLocation();
  location = location.pathname.substring(1);


  useEffect(() => {
    if (id != null || undefined && id === location) {

      const unsubscribe = data
        ? fb.firestore
          .collection('linkPages')
          .where('name', '==', id)
          .onSnapshot(snap => {
            const _page1 = [];
            snap.forEach(s => {
              _page1.push({
                ...s.data(),
                id: s.id,
              });
            });
            setPage1(_page1[0]);

          })
        : undefined;
      //  console.log('fb pull useE id:', id);
      return unsubscribe;

    }
  }, [id]);

  useEffect(() => {
    if (page1) {

      setFullName(page1.userFullName)
      setHeadline(page1.headline);
      setBiography(page1.userBio);
      setSocials(page1.socials);
      setLinks(page1.links);

    }
  }, [page1])

  useEffect(() => {
    if (departmentInfo?.headline) {

      if (!headline) {
        setHeadline(departmentInfo.headline);
      }
      if (!biography) {
        setBiography(departmentInfo.biography);
      }
      if (!socials) {
        setSocials(departmentInfo.socials);
      }

      setLinks(departmentInfo?.links);


    }


  }, [departmentInfo])

  useEffect(() => {

  }, [headline])

  const fetchCompanyLinkPages = async () => {
    const qSnapshot = fb.firestore.collection('companyLinkPages').doc(page1.companyId).collection('departments').doc(page1.department);
    qSnapshot.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        console.log('department info', doc.data())
        setDepartmentInfo(doc?.data());
      }
    })
    // const departmentDoc = qSnapshot.docs[0];
    // console.log('qSnapshot', qSnapshot.docs[0])
    // setDepartmentInfo(departmentDoc.data());
  };


  useEffect(() => {  
    if (page1) {
      if (page1?.companyId) {
        setIsCompany(true);
      }
    }
  }, [page1])

  //!!//
  useEffect(() => {
    if (isCompany === true && page1?.companyId !== null || undefined) {
      console.log('is Company', isCompany)
      fetchCompanyLinkPages();
    }
  }, [isCompany]);

  /////////////////GET BTN PICS
  const [btnPics, setBtnPics] = useState(undefined);
  useEffect(() => {
    if (links) {
      const linkIds = links.map(l => l.id);
      const getLinkPic = async () => {
        const imgInfo = [];
        for (const linkId of linkIds) {
          
          getDownloadURL(ref(getStorage(), `images/btnPic/${page1.userId}/${page1.pageId}/${linkId}`))
            .then((url) => {
              imgInfo.push({ pageId: linkId, url: url });
             
            }).catch(error => {
              console.log(error.code)
            })
        }
        setBtnPics(imgInfo);
      }
      getLinkPic()

    }

  }, [links]);

  useEffect(() => {
    if (btnPics?.length) {
      console.log('btnPics', btnPics, 'links', links)
    }
  }, [btnPics]);




  //////////////////////////////////NOt used \/
  const [windowDW, setWindowDW] = useState(null);
  const [windowDH, setWindowDH] = useState(null);



  useEffect(() => {
    setWindowDW(window.innerWidth);
    setWindowDH(window.innerHeight);
  }, []);

  let vh = windowDH * 0.01;

  document.documentElement.style.setProperty('--vh', `${vh}px`);
  //console.log('*initial VH was set in css as::', vh);


  ///////////////////////////////////////////////

  useEffect(() => {
    if (time !== null && time !== undefined) {
      const t = new Date();
      const date = ('0' + t.getDate()).slice(-2);
      const month = ('0' + (t.getMonth() + 1)).slice(-2);
      const year = t.getFullYear();
      const hours = ('0' + t.getHours()).slice(-2);
      const minutes = ('0' + t.getMinutes()).slice(-2);
      const seconds = ('0' + t.getSeconds()).slice(-2);
      const utc = t.getTime() + t.getTimezoneOffset();
      const time = `${date}-${month}-${year}, ${hours}-${minutes}-${seconds}`;
      const time2 = `${year}-${month}-${date}, ${hours}-${minutes}-${seconds}, ${utc}`;
      setTime2(`${year}-${month}-${date}, ${hours}-${minutes}-${seconds}, ${utc}`);
    }

  }, [time]);

  useEffect(() => {
    if (id === location) {
      getJson();
    }
  }, []);


  useEffect(() => {

    if (sessionStorage.getItem('whoRU') == null || undefined) {
      sessionStorage.setItem('whoRU', uuid());

    }

    //

  }, []);



  const whoRU = sessionStorage.getItem('whoRU');


  useEffect(() => {
    //  console.log('SESSION STORAGE UUID',whoRU);
  }, [whoRU]);

  //const userAgent = userInfo.call();
  //console.log('USER AGENT', userAgent)

  //
  const getJson = async () => {
    const res = await axios.get("https://ipinfo.io/json?token=60f9e4e51e2c48")
    if (res.data !== null && id == location) {
      setVisitorInfo(res.data);
      localStorage.setItem('userIPinfo', res?.data);
      console.log(res?.data)

      // console.log('res.Data', res.data);
    }
  }
  const [currentSession, setCurrentSession] = useState([]);

  ////////////////////////////////Page Visit Analytics

  useEffect(() => {
    if (page1?.pageId) {
      console.log('visiter useEffect starting', 'time of visit', timeOfVisit, 'visitor Info', visitorInfo, 'visitor id', visitorId);
      if (page1?.companyId && visitorInfo !== null || undefined && page1 != null && id == location && timeOfVisit && visitorId !== null || undefined) {
        console.log('running company analytics');
        const ft = fb?.firestore;
        ft.collection('analytics').doc(page1?.companyId).collection(page1?.pageId).doc('visitors').update({
          pageVisits: arrayUnion({ timeStamp: timeOfVisit, visitorId: visitorId, referrer: referrer, ip: visitorInfo.ip, hostname: visitorInfo.hostname, city: visitorInfo.city, country: visitorInfo.country, loc: visitorInfo.loc, org: visitorInfo.org, postal: visitorInfo.postal, region: visitorInfo.region })

        })

          .then(() => {
            console.log('***sending page visit info***');
          });

      }
      else if (visitorInfo !== null || undefined && page1 != null && id == location && timeOfVisit && visitorId !== null || undefined) {
        console.log('running normal analytics');
        const ft = fb?.firestore;
        ft.collection('analytics').doc(page1?.userId).collection(page1?.pageId).doc('visitors').update({
          pageVisits: arrayUnion({ timeStamp: timeOfVisit, visitorId: visitorId, referrer: referrer, ip: visitorInfo.ip, hostname: visitorInfo.hostname, city: visitorInfo.city, country: visitorInfo.country, loc: visitorInfo.loc, org: visitorInfo.org, postal: visitorInfo.postal, region: visitorInfo.region })

        })

          .then(() => {
            console.log('***sending page visit info***');
          });
      }
    }
  }, [page1?.pageId])

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const getHeight = () => window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  function useCurrentHeight() {
    let [height, setHeight] = useState(getHeight());

    useEffect(() => {
      let timeoutId = null;
      const resizeListener = () => {

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => setHeight(getHeight(), 50));
      };

      window.addEventListener('resize', resizeListener, false);
      //  window.addEventListener('scroll', resizeListener, false);

      return () => {
        window.removeEventListener('resize', resizeListener, false);
        //  window.removeEventListener('scroll', resizeListener, false);

      }
    }, [])
    // console.log('resize function ran');
    return height;
  }

  let h = useCurrentHeight();
  //console.log(h);



  useEffect(() => {
    let vh = h * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // console.log('vh', vh, 'h:', h);
    setWindowDH(h);
    // console.log('*after resize Listener VH was set in css as::', vh);

  }, [h]);


  //console.log('windowDH', windowDH);

  ////////////////////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////////////////////
  const handleScroll = () => {
    let height = window.innerHeight;
    //  console.log('Scrolling...', 'vh', height);
    let vh = height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    //console.log(window.scrollY);
    //console.log('*After handle Scroll VH was set in css as::', vh);
    return height;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };

  }, [window.scroll()]);


  const [color] = useState({ black: '#000000', purple: '#A082FC', white: '#FFFFFF' });
  const [userIconColor, setUserIconColor] = useState(color.black);
  const [iconBackroundColor, setIconBackroundColor] = useState(color.white);
  useEffect(() => {
    if (userIconColor == color.white) {
      setIconBackroundColor(color.black);
    } else if (userIconColor == color.black || userIconColor == color.purple) {
      setIconBackroundColor(color.white);
    }
  }, [userIconColor]);
  useEffect(() => {
    if (page1?.iconColor) {
      setUserIconColor(page1.iconColor);

    }
    if (page1?.iconBColor) {
      setIconBackroundColor(page1.iconBColor)
    }
  }, [page1?.iconColor]);



  // console.log('page:',page1);
  useEffect(() => {
    if (page) {
      // console.log('page:'+page);
    }
  }, []);

  const handleLinkClick = props => {//on click sends btnClick analytics + opens click url
    console.log('PROPS =>', props);
    const btnID = props.id;
    const btnURL = props.url;

    if (page1?.companyId !== null || undefined) {
      try {
        fb.firestore.collection('analytics').doc(page1?.companyId).collection(page1?.pageId).doc('visitors').update({
          btnClicks: arrayUnion({ timeStamp: timeOfVisit, visitorId: visitorId, btnId: btnID, btnURL: btnURL })
        })
      } catch (error) {
        console.log(error);
      }

    }
    if (1 == 1) {

      try {
        // fb?.firestore?.collection('analytics').doc(page1.userId).collection('btnClicks').doc(page1.pageId).update({
        fb?.firestore?.collection('analytics').doc(page1?.userId).collection(page1?.pageId).doc('visitors').update({
          // [time]: [{ visitorId: whoRU }, { referrer: referrer, pageId: page1.pageId, btnId: props.id, btnURL: props.url, createDate: time }, visitorInfo]
          btnClicks: arrayUnion({ timeStamp: timeOfVisit, visitorId: visitorId, btnId: btnID, btnURL: btnURL })
        })
      } catch (error) {
        console.log(error);
      }
      finally {
        window.open(props.url);
      };
    }
  }


  useEffect(() => {
    if (page1?.pageId != null) {
      const storageD = getStorage();

      getDownloadURL(ref(storageD, `images/profilePic/${page1.userId}/${page1.pageId}`))
        .then((url) => {
          setImage(url);
        }).catch(error => {
          if (error.code === 'storage/object-not-found') {
            setImage(yologoicon);/////////////////////////////////////////////////////
            return Promise.resolve(false);
          } else {
            return Promise.reject(error);
          }
        });
      getDownloadURL(ref(storageD, `contacts/${page1?.userId}/${page1?.pageId}/${page1?.userFullName}.vcf`))
        .then((url) => {
          setVCF(url);
        }).catch(error => {
          if (error.code === 'storage/object-not-found') {
            setNoVcf(true);
            console.log('no vcf', noVcf);
            return Promise.resolve(false);
          } else {
            return Promise.reject(error);
          }
        });
    }
  }, [page1?.pageId]);



  useEffect(() => {
    if (btnPics && links) {

     // console.log('!*!btnPics', btnPics, 'links', links);
      const addImgUrlToLinks = async () => {
        const updatedLinks = [];
        for (const link of links) {
          const linkImg = await btnPics?.find(i => i?.pageId === link?.id);
          if (linkImg) {
            if (linkImg.hasOwnProperty('url')) {
              updatedLinks.push({ ...link, imgUrl: linkImg.url });
            } else {
              setTimeout(() => {
                const updatedLinkImg = btnPics?.find(i => i?.pageId === link?.id);
                if (updatedLinkImg && updatedLinkImg.hasOwnProperty('url')) {
                  updatedLinks.push({ ...link, imgUrl: updatedLinkImg.url });
                  setLinks(updatedLinks);
                }
              }, 2000);
              updatedLinks.push({ ...link, imgUrl: null });
            }
          } else {
            updatedLinks.push(link);
          }
        }
        setUpdatedLinks(updatedLinks);
      };

      addImgUrlToLinks();




    }
  }, [btnPics?.length])

  useEffect(() => {
    console.log('updated links', updatedLinks, 'btnPics', btnPics)
  },[updatedLinks])


  const Links3 = () => {
    const [btnsWPics, setBtnsWPics] = useState(undefined);
   // useEffect(())

    return(
      <div className={styles.linkBox}>
        <div className={styles.linkRow}>
          
          </div>
          </div>
      
    )
  }



  const Links2 = () => {

    return (
      <div className={styles.linkBox}>
        <div className={styles.linkRow}>
          {updatedLinks?.map((l, index) => {// Web Links
            return (
              //  {btnPics  null (<div></div>) : (<div></div>)}
              <div key={index} className={styles.btnOutside}>
                {l.imageURL ? (
                  <div onClick={() => { handleLinkClick(l) }} key={index} className={styles.btnWithImg} value={l}>
                    {l.imageURL &&
                      <div className={styles.linkImgCont} >
                        <img className={styles.linkImg} src={l.imageURL}
                        />
                        <div className={styles.imgGrad}></div>
                      </div>
                    }
                    <div className={styles.btnImgSubTxt}>
                      {l.name}
                    </div>
                  </div>
                ) : (
                  <div className={styles.btnCont}>
                    <div onClick={() => { handleLinkClick(l) }} key={index} className={styles.btn}  value={l}>
                      {l.name}
                    </div>
                  </div>)}
              </div>
            );
          })}
        </div>
      </div>

    )
  }

/*<div className={styles.pageBackground}
        style={{ backgroundColor: page1?.pBColor }}
      />*/

  return (
    <div className={styles.outside} style={{ backgroundColor: page1?.pBColor }}  >
      
      

      {isAuthed &&
        <AuthedNav className={styles.authedNav} />
      }

      {page1?.showContactD === true || page1?.showContactX === true ? (
        <PageContact value={[page1, image, page1?.pBColor]} />
      ) : (
        <div></div>

      )}


      <div className={styles.main} >
        

        <div className={styles.profilePicBorder}>

          <img className={styles.image} src={image} />

        </div >
        <div className={styles.textBox}>
          {fullName &&
            <div className={styles.name}> {fullName} </div>
          }
          {headline &&
            <div className={styles.headline}> {headline} </div>
          }
          {biography &&
            <div className={styles.bio}> {biography} </div>
          }
        </div>

        {socials &&

          <div className={styles.iconArray1}  >
            {socials?.map((l, index) => {/////////////Social Links
              if (page1.socials != null) {
                return (
                  <div key={index} onClick={() => handleLinkClick(l)} className={styles.socialCont}>
                    <div className={styles.eachBtn}  >
                      <Icon path={l.logo}
                        className={styles.eachIcon}
                        style={{ backgroundColor: iconBackroundColor }}
                        size="5vh"
                        color={userIconColor}
                        value={l.name}
                      />
                    </div>
                  </div>
                );
              }
            })}

          </div>
        }


        {updatedLinks && <Links2 />}











      </div>

      <img src={yologoicon} className={styles.mainPeace}
        onClick={() => history.push('/')} />


    </div>
  );


};
