
import { Fragment, useEffect, useState } from 'react';

import styles from './styles.module.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Icon from '@mdi/react';
import { mdiClose, mdiDelete, mdiDotsGrid, mdiDotsHorizontal, mdiPencil, mdiTrashCan } from '@mdi/js';
import { ColorPicker } from '../ColorPicker/ColorPicker';


export function DragSocials({ userSocials, setUserSocials, userIconColor, setUserIconColor, iconBackgroundColor, setIconBackgroundColor }) {

    const social = userSocials;
    const [characters, setCharacters] = useState(social);
    const [editOpen, setEditOpen] = useState(false);
    const [clickedIndex, setClickedIndex] = useState(null);
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [colorPicker2Open, setColorPicker2Open] = useState(false);
    // const [ setIconColor] = useState()
    const [color1, setColor1] = useState(userIconColor);

    useEffect(() => {
        setCharacters(userSocials);
    }, [userSocials])

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setCharacters(items);
    }

    useEffect(() => {
        console.log('characters changed', characters);
        setUserSocials(characters);

    }, [characters])

    const deleteLink = (index) => {
        setCharacters(characters.filter((_, i) => i !== index));
        setEditOpen(!editOpen);
        setClickedIndex(null);
        console.log('delete link', index);
    };


    useEffect(() => {
        if (userSocials) {
            console.log('user socials from drag social', userSocials)
        }
    }, [userSocials])

    //const toggleEdit = () => { setEditOpen(!editOpen) };
    function toggleEdit(index) {
        setClickedIndex(index);
        setEditOpen(!editOpen);
    }
    function handleClose() {
        setEditOpen(!editOpen);
        setClickedIndex(null);
    }

    const [isEditUrlOpen, setIsEditUrlOpen] = useState(false);
    const [currentIcon, setCurrentIcon] = useState();

    const toggleEditUrl = (index) => {
        console.log('editLink index', characters[index])
        if (isEditUrlOpen === false) {
            setCurrentIcon(characters[index])
        }
        setIsEditUrlOpen(!isEditUrlOpen)
        toggleEdit();

    };
    useEffect(() => {
        console.log("Icon Color from Drag Social", userIconColor)
    }, [userIconColor])
    useEffect(() => {
        console.log("Icon Color1 from Drag Social", color1)
    }, [color1])

    const [colorPickerKey, setColorPickerKey] = useState(0);

    const EditIcon = ({userIconColor, setUserIconColor, iconBackgroundColor, setIconBackgroundColor}) => {
        const [iC, setIC] = useState(userIconColor);
        const [iBC, setIBC] = useState(iconBackgroundColor);

        useEffect(() => {

            console.log('ic', iC, 'ibc', iBC);

        },[iC, iBC])
        //console.log('ediIcon', currentIcon);
        return (
            <div className={styles.popBackground}>
                <div className={styles.socialPop}>
                    <div className={styles.closeCont}> <Icon path={mdiClose} size={1} className={styles.closeSocialPop} onClick={toggleEditUrl} /></div>
                    <div className={styles.titleS}>Update Url:</div>
                    <div className={styles.center}>
                        <Icon path={currentIcon.logo}
                            size="4vh"
                            className={styles.eachIcon}
                            style={{ backgroundColor: iBC }}
                            color={iC}
                        />
                    </div>

                    <input className={styles.txtA}
                        key="currentSocial"
                        // autoFocus="autoFocus"
                        placeholder="Social URL"
                        value={currentIcon.url}
                        onBlur={e => setCurrentIcon({ ...currentIcon, url: e.target.value })}////////////////////   
                    />

                    <button className={styles.changeBtn}
                        style={{ backgroundColor: colorPickerOpen ? 'rgb(231, 229, 229' : '' }}
                        onClick={() => {
                            if (colorPicker2Open) {
                                setColorPicker2Open(false);
                            }
                            setColorPickerOpen(!colorPickerOpen)
                        }}
                    >Change Icon Color</button>
                    <button className={styles.changeBtn}
                        style={{ backgroundColor: colorPicker2Open ? 'rgb(231, 229, 229' : '' }}
                        onClick={() => {
                            if (colorPickerOpen) {
                                setColorPickerOpen(false)
                            }
                            setColorPicker2Open(!colorPicker2Open)
                        }}
                    >Change Background Color</button>
                    <Fragment>
                        {colorPickerOpen && <ColorPicker userIconColor={iC} setUserIconColor={setIC} mode="iconColor" />}
                        {colorPicker2Open && <ColorPicker iconBackgroundColor={iBC} setIconBackgroundColor={setIBC} mode="backgroundColor" />}
                    </Fragment>
                    <button
                        className={styles.addSocialBtn}
                        onClick={() => {
                            //  setUserSocials([...userSocials, { name: currentIcon.name, url: currentIcon.url, logo: currentIcon.logo, id: currentIcon.id }]);
                            var foundIndex = userSocials.findIndex(x => x.id == currentIcon.id);
                            console.log('found index', foundIndex);
                            userSocials[foundIndex] = currentIcon;
                            setCurrentIcon({ name: '', logo: '', url: '', id: '' });
                            // const socialsToSend = userSocials;
                            setUserIconColor(iC);
                            setIconBackgroundColor(iBC);

                            toggleEditUrl();
                        }}
                        disabled={!currentIcon.url || !currentIcon.name}
                    >
                        Save Changes
                    </button>

                </div>
            </div>
        )
    }


    //onClick={() => deleteLink(index)}

    return (
        <div className={styles.main}>
            <DragDropContext onDragEnd={handleOnDragEnd} className={styles.dragDrop} >
                <Droppable droppableId='characters' className={styles.droppable} direction="horizontal">
                    {(provided) => (
                        <div className={styles.char} {...provided.droppableProps} ref={provided.innerRef}>
                            {characters.map(({ id, name, url, logo }, index, l) => {
                                return (
                                    <Draggable key={id} draggableId={id} index={index} className={styles.draggable} value={l}  >
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.list}  >
                                                <Icon path={logo}
                                                    size="4vh"
                                                    className={styles.eachIcon}
                                                    style={{ backgroundColor: iconBackgroundColor }}
                                                    color={userIconColor}
                                                />
                                                <Icon path={mdiDotsHorizontal} height="20px" className={styles.editBtn}
                                                    onClick={() => toggleEdit(index)}
                                                />
                                                {editOpen && index == clickedIndex &&
                                                    <div className={styles.editCont}>
                                                        <div className={styles.editBox}>
                                                            <Icon path={mdiClose} size={1} className={styles.editClose} onClick={handleClose} />

                                                            <div className={styles.editUrlBtn} onClick={() => toggleEditUrl(index)}
                                                            >Edit Url <Icon path={mdiPencil} size={1} className={styles.pencil}
                                                                /></div>

                                                            <div className={styles.deleteBtn} onClick={() => deleteLink(index)}>
                                                                Delete Icon <Icon path={mdiDelete} size={1} className={styles.trash} /></div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )}

                                    </Draggable>

                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}

                </Droppable>

            </DragDropContext>

            {isEditUrlOpen &&  <EditIcon userIconColor={userIconColor} setUserIconColor={setUserIconColor} iconBackgroundColor={iconBackgroundColor} setIconBackgroundColor={setIconBackgroundColor}/>}

        </div>
    )

}