import styles from './styles.module.css';
import { Login } from '../Login';
import { useAuth } from '../../hooks/useAuth';
import { Signup } from '../Signup';
import { SignupTeam } from '../SignupTeam';
import { Route, Switch } from 'react-router-dom';
import { CreatePage, CreatePageInfo } from '../CreatePage';
import { Analytics } from '../Analytics/Analytics';
import { DashboardAdmin } from '../DashboardAdmin/DashboardAdmin';
import { PublicPage } from '../PublicPage';
import { MyContacts } from '../MyContacts/MyContacts';
import { Home } from '../Home';
import { PublicVcf } from '../PublicVcF/PublicVcf';
import { Pages } from '../Pages/Pages';
import { Dashboard } from '../Dashboard';
import { PrivatePage } from '../PrivatePage/PrivatePage';
import { Settings } from '../Settings/Settings';
import { TOC } from '../TOC';
import { Priv } from '../Priv/Priv';
import { VirtualCard } from '../VirtualCard/VirtualCard';
import { CreateTeamDetails } from '../CreateTeamDetails/CreateTeamDetails';
import { EditTeamPagesAdmin } from '../CreatePage/EditTeamPagesAdmin';
import { CreateEmployeePage } from '../CreatePage/CreateEmployeePage';
import { EmployeeSignin } from '../CreatePage/EmployeeSignin';
import { Stream } from 'stream-browserify';
import { AdminAnalytics } from '../Analytics/AdminAnalytics';
import { PricingPlans } from '../PricingPlans/PricingPlans';
export const App = () => {
  window.stream = Stream;
  const { isAuthed, authUser } = useAuth();
///edit-default-page-proterties
  return (
    <div className={styles.main}>
      {authUser === undefined ? (
        <></>
      ) : isAuthed ? (
        <Switch>
          <Route exact path='/privacy-policy' component={Priv}/>
          <Route exact path='/admin/dashboard' component={DashboardAdmin} />
          <Route exact path='/virtual-page/:tyler' component={VirtualCard} />
          <Route exact path={['/', '/dashboard']} component={Dashboard} />
          <Route exact path='/pages' component={Pages} />
          <Route exact path='/terms-and-conditions' component={TOC} />
          <Route exact path='/pages' component={Pages} />
          <Route exact path='/edit-default-page-proterties' component={EditTeamPagesAdmin} />
          <Route exact path ='/create-team-details' component={CreateTeamDetails}/>
          <Route exact path ='/create-employee-page' component={CreateEmployeePage}/>
          <Route exact path={['/create-page-details/:id?', '/create-team-page', '/edit-page-details/:id']} component={CreatePageInfo} />
          <Route exact path=
            {['/create-page/:id', '/edit-page-content/:id']}//'create-team-page',
            component={CreatePage}
          />
          <Route exact path ='/pricing-plans' component={PricingPlans}/>
          <Route exact path='/admin-analytics/:id' component={AdminAnalytics}/>

          <Route exact path='/analytics/:id' component={Analytics} />
          <Route exact path='/my-contacts/:id' component={MyContacts} />
          <Route exact path='/vcf-creator' component={PublicVcf} />
          <Route exact path='/settings/:id' component={Settings} />
          <Route exact path='/v/:id/:type?' component={PrivatePage} />
          <Route exact path='/:id' component={PublicPage} />
          <Route path={['/']} component={Home} />


        </Switch>
      ) : (
        <Switch>
          <Route path='/employee-signin/:companyId?/:department?/:email?/:fullName?/:lang?' component={EmployeeSignin}/>
          <Route exact path='/privacy-policy' component={Priv}/>
          <Route exact path='/terms-and-conditions' component={TOC} />
          <Route exact path='/team-signup' component={SignupTeam} />
          <Route exact path='/signup/:id?' component={Signup} />
          <Route exact path='/login' component={Login} />
          <Route exapt path='/vcf-creator' component={PublicVcf} />
          <Route exact path='/v/:id/:type?' component={PrivatePage} />
          <Route path='/:id' component={PublicPage} />
          <Route path={['/']} component={Home} />



        </Switch>

      )}
    </div>
  );
};











/*
export function App(){
  return (
    //<Router>
      <div className={styles.main}>
      <Signup />
      </div>

    //</Router>
  );
}

*/