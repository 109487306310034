import { useEffect, useState } from "react";
import { AuthedNav } from "../AuthedNav"
import styles from './styles.module.css';



export const PricingPlans = () => {
    const plans = {
        monthly: {
            freeTier: {
                price: "Free",
                included: ["Unlimited links and icons", 'Clicks and views for past 30 days']
            },
            starterTier: {
                price: "$5 USD"
            }
        },
        annual: {
            freeTier: {
                price: "Free",
                included: ["Unlimited links and icons", 'Clicks and views for past 30 days']
            }
        },

    }



    const [selectedOccurance, setSelectedOccurance] = useState('monthly');
    const [planFeatures, setPlanFeatures] = useState(plans.monthly);


    const radioSelecter = event => {
        setSelectedOccurance(event.target.value)
        if (event.target.value === 'annual') {
            setPlanFeatures(plans.annual)
        } else {
            setPlanFeatures(plans.monthly)
        }


    }
    useEffect(() => {
        console.log('selected', selectedOccurance)
    }, [selectedOccurance])

    return (
        <div className={styles.main}>
            <AuthedNav />
            <div className={styles.title}>Find the plan for you</div>
            <div className={styles.subTitle}>Feel free to change anytime</div>

            <div className={styles.recurringContainer} >
                <label>
                    <input
                        className={styles.radioSelecter}
                        style={{ border: "none" }}
                        type="radio"
                        value="monthly"
                        checked={selectedOccurance === 'monthly'}
                        onChange={radioSelecter}

                    />
                    Monthly
                </label>
                <label>
                    <input
                        className={styles.radioSelecter}
                        type="radio"
                        value="annual"
                        checked={selectedOccurance === 'annual'}
                        onChange={radioSelecter}
                    />
                    Annual
                </label>
            </div>

            <div className={styles.plansContainer}>

                <div>
                    <h3>Free</h3>
                    <p>Price: {plans[selectedOccurance].freeTier.price}</p>
                    <p>Included: {plans[selectedOccurance].freeTier.included.join(', ')}</p>
                </div>
            </div>
        </div>
    )
}