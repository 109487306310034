import { useEffect, useState } from "react";
import { useAuth, useSelectedPage } from "../../hooks";
import { AuthedNav } from "../AuthedNav";
import styles from "./styles.module.css";
import background from '../../images/virtualCard.png';
import { QRCodeCanvas } from "qrcode.react";
import { useHistory } from "react-router-dom";


export const VirtualCard = () => {

    const {isAuthed} = useAuth();
    const p = useSelectedPage();
    const [page, setPage] = useState();
    const history = useHistory()

    useEffect(() => {
        if(p){
           setPage(p[0])           
        }
    },[p])
    useEffect(() => {
        if(page){          
           console.log('Page=>', page)
        }
    },[page])

    const qrcode = (
        <QRCodeCanvas
        id="qrCode"
        value={`https://yoitsme.co/${page?.name}`}
        size={165}
        bgColor={"rgba(255, 255, 255, 0)"}
        fgColor="#ffffff"
        />
    )

    return(
        <div className={styles.outside}>
             <div className={styles.navBtnRow}>
                <div className={styles.leftBtnCont}>
                    <button
                        onClick={() => { history.push('/') }}
                        className={styles.navBtn}>home</button>
                </div>
                <div className={styles.rightBtnGroup}>
                  
                    
                </div>
            </div>
            

            <div className={styles.main}>
           
                <div className={styles.vCard} style={{ backgroundImage: 'url(' + background + ')', backgroundSize: 'cover', backgroundPosition: 'center', height: '100%',  }}>
                <div className={styles.name}>{page?.userFullName}</div>
                <div className={styles.qr}>{qrcode}</div>
                </div>
            </div>

        </div>
    )

}