import { mdiClose } from '@mdi/js';
import styles from './styles.module.css';
import React, { useState, useEffect } from "react";
import Icon from '@mdi/react';
import ReactPhoneInput from 'react-phone-input-mui';
import { TextField } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import FileSaver from 'file-saver';


export const PublicVcf = () => {
    const [currentVCF, setCurrentVCF] = useState({
        fName: '', lName: '', title: '', wEmail: '', email: '', mobile: '',
        workNumber: '', address: '', website: '', address: '', organization: ''
    });
    const [mobilePhoneNum, setMobilePhoneNum] = useState('');
    const [workPhoneNum, setWorkPhoneNum] = useState('');
    const [vFile, setVFile] = useState();
    const [vCreated, setVCreated] = useState(false);
    const handleClose = () => {

    }
    const handleVCFSubmit = (e) => {
        console.log('clicking');
        var VCF = new Blob(
            [`
BEGIN:VCARD
VERSION:3.0
N:${currentVCF.lName};${currentVCF.fName};;;
FN:${currentVCF.fName} ${currentVCF.lName}
ORG:${currentVCF.organization}
EMAIL;type=INTERNET;type=HOME;:${currentVCF.email}
EMAIL;type=INTERNET;type=WORK;:${currentVCF.wEmail}
TEL;type=CELL:${mobilePhoneNum}
TEL;type=WORK:${workPhoneNum}
ADR;CHARSET=UTF-8; TYPE=WORK:;;${currentVCF.address}
TITLE;CHARSET=UTF-8:${currentVCF.title}
URL;type=WORK;CHARSET=UTF-8:${currentVCF.website}        
END:VCARD`
              ],
              { type: "text/vcard" }
            );
            console.log(VCF);
            console.log(URL.createObjectURL(VCF));
            setVFile(URL.createObjectURL(VCF));
            setVCreated(true);
               
     }

     const downloadVCard = () => {
        FileSaver.saveAs(
           vFile,
            `${currentVCF.fName}${currentVCF.lName}.vcf`,
            true

        ); 

     }
    
    useEffect(() => {
        console.log('mobile #:' + mobilePhoneNum);
        
    }, [mobilePhoneNum]);

    useEffect(() => {
        console.log('work #:' + workPhoneNum);
    }, [workPhoneNum]);


    return (
        <div className={styles.box}>
            <span className={styles.closeIcon} onClick={handleClose}>
                <Icon path={mdiClose} size='20px' />
            </span>

            <form className={styles.form}>
                <input
                    className={styles.input}
                    id='firstName'
                    type="text"
                    maxLength="20"
                    placeholder='First Name'
                    value={currentVCF.fName}
                    onChange={e => setCurrentVCF({ ...currentVCF, fName: e.target.value })}
                />
                <input
                    className={styles.input}
                    id='lastName'
                    type="text"
                    maxLength="25"
                    placeholder='Last Name'
                    value={currentVCF.lName}
                    onChange={e => setCurrentVCF({ ...currentVCF, lName: e.target.value })}
                />

                <div>
                <p1>Mobile Phone:</p1>
                <ReactPhoneInput
                    value={mobilePhoneNum}
                    onChange={setMobilePhoneNum}
                    component={TextField}
                />
                </div>
                <p1>Work Phone</p1>
                <PhoneInput
                    value={workPhoneNum}
                    onChange={setWorkPhoneNum}
                    component={TextField}
                    specialLabel=""

                />



                <input
                    className={styles.input}
                    id='work'
                    type="text"
                    maxLength="40"
                    placeholder='Email'
                    value={currentVCF.email}
                    onChange={e => setCurrentVCF({ ...currentVCF, email: e.target.value })}
                />
                <input
                    className={styles.input}
                    id='work'
                    type="text"
                    maxLength="40"
                    placeholder=' Work Email'
                    value={currentVCF.wEmail}
                    onChange={e => setCurrentVCF({ ...currentVCF, wEmail: e.target.value })}
                />
                <input
                    className={styles.input}
                    id='work'
                    type="text"
                    maxLength="40"
                    placeholder='Organization'
                    value={currentVCF.organization}
                    onChange={e => setCurrentVCF({ ...currentVCF, organization: e.target.value })}
                />
                <input
                    className={styles.input}
                    id='work'
                    type="text"
                    maxLength="40"
                    placeholder='Title'
                    value={currentVCF.title}
                    onChange={e => setCurrentVCF({ ...currentVCF, title: e.target.value })}
                />
                <input
                    className={styles.input}
                    id='website'
                    type="text"
                    maxLength="60"
                    placeholder='Website'
                    value={currentVCF.website}
                    onChange={e => setCurrentVCF({ ...currentVCF, website: e.target.value })}
                />
                <input
                    className={styles.input}
                    id='address'
                    type="text"

                    placeholder='Address'
                    value={currentVCF.address}
                    onChange={e => setCurrentVCF({ ...currentVCF, address: e.target.value })}
                />

            </form>

            <button className={styles.upload}
                onClick={handleVCFSubmit}>
                Create vCard
            </button>
            
            {vCreated &&
            <button className={styles.upload}
                onClick={downloadVCard}>
                Download vCard
            </button>
            }


        </div>

    );
}