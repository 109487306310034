import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom"
import { emailRegex } from "../../shared";
import { fb } from "../../shared/service";



export const EmployeeSignin = () => {
    const auth = getAuth();
    const [pw, setPW] = useState('');
    const [verifyPW, setVerifyPW] = useState(null);
    const [valid, setValid] = useState(false);
    const history = useHistory();
    const [error, setError] =useState('');
    const location = useLocation(); 
    const [email1, setEmail1] = useState();
    useEffect(() => {
        if(auth){
            console.log("AUTH", auth)
        }
    },[auth])

    const {companyId, department, email, fullName} = useParams();
    console.log('log anything')


    useEffect(() => {
        console.log('user params',companyId, department, email, fullName )
    },[companyId, department, email, fullName]);

    useEffect(() => {
        setValid(
            email && emailRegex.test(email) && pw && verifyPW && pw === verifyPW);
    },[email, pw, verifyPW]);

    useEffect(() => {
        if(isSignInWithEmailLink(auth, window.location.href)) {
    
            let email = null;
            if(!email) {
                email = window.prompt('Please provide your email for confirmation')
            }
            signInWithEmailLink(auth, email, window.location.href)
            .then((res) => {
                console.log('result', res, 'result user', res.additionalUserInfo.isNewUser);
                history.push('/');
    
            }).catch((error) => {
                console.log('error', error)
            });
            history.push('/');
        }

    },[auth, location])
    

    const signup = () => {
        if(valid) {
            fb
             .auth
             .createUserWithEmailAndPassword(email,pw)
             .then((userCredential) => {
                const user = userCredential.user;
                console.log('Signup Successfull!', user);
                if(user){
                    fb.firestore.collection('roles').doc(companyId).set({
                        [user.uid]: {
                            admin: true

                        }
                        
                    })
                }
             history.push('/create-team-details', {companyId: companyId});
             })
             .catch((error) => {
                if (
                    error.code ==='auth/email-already-in-use'
                ) {
                    setError('Email Already in Use')
                }
                if(
                    error.code ==='auth/weak-password'
                ) {
                    setError('Weak Password! Your password must be at least 6 characters.')
                }
                console.log('Code =>',error.code, 'Message => ',error.message)
               /* const code = error.code;
                if(code == '(auth/email-aready-in-use)'){
                    console.log('%!email already in use!%');
                }*/
             })
            
        }
    };


   

    return(
        <div>Employee Signin</div>
    )
}