import { usePages, useAuth } from "../../hooks";
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
//import { DashboardLink } from "../DashboardLink/DashboardLink";
import { fb } from "../../shared/service";
import { AuthedNav } from "../AuthedNav";
import { query, where, collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react/cjs/react.development";
import { DashboardLinkAdmin, DashboardLinkAmin } from '../DashboardLinkAdmin/DashboardLinkAdmin';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GetCompanyInfo } from "./GetCompanyInfo";
import { FilterDepartment } from "./FilterDepartment";
import Icon from '@mdi/react';
import { mdiChartTimelineVariant } from "@mdi/js";

export const DashboardAdmin = () => {
    const { authUser } = useAuth();

    const history = useHistory();
    const pages = usePages(authUser?.uid);
    const { companyId, companyInfo } = GetCompanyInfo();


    const [department, setDepartment] = useState('');

    const [filteredPages, setFilteredPages] = useState();


    const [q, setQ] = useState();

    useEffect(() => {
        if (pages !== undefined && pages !== null) {
            const db = fb?.firestore;
            console.log('db:', db);
            setQ(query(collection(db, "linkPages"), where('companyId', "==", pages[0]?.companyId)));
            //console.log('q:', q);
        }
    }, [pages]);


    useEffect(() => {
        if (q) {
            getTeamPages();
            console.log('q:', q);
        }

    }, [q]);

    var teamPages = [];
    const [tp, setTp] = useState({});

    const getTeamPages = async () => {

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            teamPages.push(doc.data());
            //console.log(doc.id, ' => ', doc.data());
            console.log('teamPages:', teamPages);

            setTp([teamPages]);
            console.log('tP:', tp);
            return teamPages;

        });
    }

    useEffect(() => {
        if (pages) {
            console.log("PAGES =>", pages)
        }
    }, [pages])

    const filterDepartment = (event) => {
        setDepartment(event.target.value);
    };
    useEffect(() => {
        {
            console.log("Selected department", department);
        }
    }, [department])

    useEffect(() => {
        if (tp) {
            setFilteredPages(tp[0])
        }

    }, [tp]);

    useEffect(() => {
        if (department) {
            setFilteredPages(tp[0]?.filter((p) => p.department === department))
        } else {
            setFilteredPages(tp[0])
        }

    }, [department])
    /*  <FormControl sx={{ m: 1, minWidth: 300, height: 33 }}>
                        <InputLabel>Filter by Department</InputLabel>
                        <Select
                            value={department}
                            onChange={filterDepartment}
                            autoWidth
                            label="Department"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {companyInfo?.departments?.map((dep) => (
                                <MenuItem key={dep.index} value={dep.label}>
                                    {dep.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>*/

    return (
        <div className={styles.main2}>
            <AuthedNav />
            <div className={styles.main}>
                <h1 className={styles.title}>Admin Dashboard</h1>

                <div
                    onClick={() => {
                        const data = {
                            teamPages: tp[0],
                            companyId: companyId,
                            companyInfo: companyInfo,
                        };
                        history.push('/admin-analytics', data)
                    }}
                    className={styles.createPageBtn}>

                    Admin Analytics
                    <Icon path={mdiChartTimelineVariant} size={1} color="white" />
                </div>



                <FilterDepartment department={department} setDepartment={setDepartment} />

                <button className={styles.editDeptBtn}
                    onClick={() => { history.push('/edit-default-page-proterties') }}
                >Edit Default Department Page Properties</button>
                <h2 className={styles.title}>{companyInfo?.name} Pages:</h2>

                {filteredPages?.map((p, index) => {
                    return <DashboardLinkAdmin className={styles.page} key={index} page={p} />;
                })}

                <div
                    onClick={(page = page) => history.push('/create-employee-page')}
                    className={styles.createPageBtn}>
                    Create New Employee Account
                </div>
            </div>
        </div>
    );
};

