import styles from './styles.module.css';
import {MdEdit, MdDelete, MdRemoveRedEye} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { fb } from '../../shared/service';
import { useEffect } from 'react/cjs/react.development';
import { deleteObject, ref } from 'firebase/storage';
import Icon from '@mdi/react';
import { mdiTrashCan } from '@mdi/js';
import { Identity } from '@mui/base';




export const PagesLink = ({ page }) => {
    const history = useHistory();
  
    const viewPage = () => history.push(`/${page.id}`);
    const editPage = () => history.push(`/edit-page-details/${page.id}`);
    const deletePage = () => {
      if (window.confirm(`Are you sure you want to delete ${page.name}?`)) {
        fb.firestore.collection('linkPages').doc(page.id).delete();
        const storage = fb.storage;
        const deletePic = ref(storage, `images/profilePic/${page.pageId}`);
        deleteObject(deletePic).then(()=> {

        }).catch((error)=>{
            console.log('image delete error');
        });
        const deleteVCF = ref(storage, `contacts/${page.pageId}`);
        deleteObject(deleteVCF).then(()=> {

        }).catch((error)=>{
            console.log('vcf delete error');
        });
      }
    };
    //useEffect(() => console.log('dash links {page}'+ page),[page]);
 // console.log('dash links {page}:'+ page.pageId);
    return (
      <div className={styles.main}>
        <div onClick={() =>
        {history.push({
         pathname: `/my-contacts/${page.pageId}`,
         state: page
        })}} 
        className={styles.title}>/ {page.id}</div>
  
        <div onClick={viewPage} className={styles.view}>
          <MdRemoveRedEye />
        </div>
        <div onClick={editPage} className={styles.edit}>
          <MdEdit />
        </div>
        <div onClick={deletePage} className={styles.delete}>
          <Icon path = {mdiTrashCan} />
        </div>
      </div>
    );
  };