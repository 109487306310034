import styles from './styles.module.css';
import { fb } from '../../shared/service';
import { usePages, useAuth, useSelectedPage } from "../../hooks";
import { useHistory } from 'react-router-dom';
import yologoicon from '../../images/yologoicon.ico';
import { mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';
import { useState, useEffect } from 'react';

import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import stockPic from '../../images/noProfilePic.svg';
import { useRef } from 'react';
export const AuthedNav = () => {
    const history = useHistory();
    
    const [accountDropOpen, setAccountDropOpen] = useState(false);
    const r = useRef(null);
    const [page, setPage] = useState();
    const [multPages, setMultPages] = useState(false);
    const Logout = () => {
        fb.auth.signOut().then(() => history.push('/'));
    }
    
    
    const handleClickOutside = (event) => {
        if (r.current && !r.current.contains(event.target)) {
            setAccountDropOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

   // return { ref, isComponentVisible, setIsComponentVisible };



    

    const toggleAccountDrop = () => {
        setAccountDropOpen(!accountDropOpen);
    }
    const { authUser } = useAuth();
    const pages = usePages(authUser?.uid);

    const selectedPage = useSelectedPage();

    useEffect(() => {
        if (selectedPage) {
            setPage(selectedPage[0]);
        }
    }, [selectedPage])
    //const [selectedPage, setSelectedPage] = useState();
    //const [selectedPageId, setSelectedPageId] = useState();
    //testing sync diff

    useEffect(() => {
      //  console.log('authednave pages', pages);
        if (pages) {
            if (pages.length > 1) {
                setMultPages(!multPages);
            }
        }
    }, [pages])


    const [image, setImage] = useState();

    useEffect(() => {
        if (selectedPage !== undefined || null && selectedPage.length > 0) {

            if (selectedPage[0] !== undefined || null) {
                const storageD = getStorage();
               // console.log('in useEffect selected page:', selectedPage);

                getDownloadURL(ref(storageD, `images/profilePic/${selectedPage[0].userId}/${selectedPage[0].pageId}`))
                    .then((url) => {
                        setImage(url);
                        console.log('Authed nave Use effect ran "setImage(url)"')
                    }).catch(error => {
                        if (error.code === 'storage/object-not-found') {
                            setImage(stockPic);
                            // setImage(yologoicon);/////////////////////////////////////////////////////
                            
                            return Promise.resolve(false);
                        } else {
                            return Promise.reject(error);
                        }
                    });
               // console.log('image', image);

            }

            if (selectedPage.length == 0) {
               // console.log('SELECTED PAGE LENGTH IS 0 ');
                setImage(yologoicon);
            }



        }
        return selectedPage;


    }, [selectedPage]);


    ///btn directory
    const viewPage = () => history.push(`/${page.id}`);

    const editPage = () => history.push({
        pathname: `/edit-page-content/${page.id}`,
        state: page
    });

    const analytics = () => history.push({
        pathname: `/analytics/${page.id}`,
        state: page
    });
    const virtualPage = () => history.push({
        pathname: `/virtual-page/${page.id}`,
        state: page
    });
    const openSettings = () => history.push({
        pathname: `/settings/${page.id}`,
        state: page
    });



    const AccountDrop = () => {

        return (
            <div className={styles.float} ref={r} >
                {accountDropOpen &&

                    <div className={styles.accountDrop}>
                        <div className={styles.greyBtn} onClick={() => {
                            history.push({
                                pathname: `/`,
                                
                            })
                        }}>Dashboard</div>
                        <div className={styles.whiteBtn} onClick={virtualPage}
                        >Virtual Card</div>
                        
                        <div className={styles.greyBtn} onClick={editPage}
                        >Edit Page</div>
                        <div className={styles.whiteBtn} onClick={viewPage}
                        >Live Page</div>
                        
                        <div className={styles.greyBtn} onClick={() => {
                            history.push({
                                pathname: `/my-contacts/${page.pageId}`,
                                state: page
                            })
                        }}
                        >Contacts</div>
                        <div className={styles.whiteBtn} onClick={analytics}
                        >Analytics</div>
                        <div className={styles.greyBtn} onClick={openSettings}
                        >Settings</div>



                        
                        <div onClick={Logout}
                            className={styles.logout}
                        >Logout</div>

                    </div>}

            </div>

        )

    }

    return (
        <div className={styles.main2} props={selectedPage}>
            <div className={styles.main}>

                <img src={yologoicon}
                    className={styles.peaceIco}
                    onClick={() => { history.push('/') }}
                />

                <div onClick={toggleAccountDrop}
                    className={styles.accountDropToggle}>
                    <img src={image} className={styles.image}
                    />
                </div>


            </div>

            {accountDropOpen ? (
                <AccountDrop />

            ) : (
                <div></div>

            )}

        </div>



    )


}