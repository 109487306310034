import { useHistory, useParams } from 'react-router-dom';
import styles from './styles.module.css';

import ReactSwitch from 'react-switch';
import { useAuth, usePages, useSelectedPage } from '../../hooks';
import { useEffect, useState } from 'react';
import { AuthedNav } from '../AuthedNav';
import { Switch } from '@mui/material';
import { fb } from '../../shared/service';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

export const Settings = () => {
    const { authUser } = useAuth();
    const history = useHistory();
    const pages = usePages(authUser?.uid);
    const [checkedD, setCheckedD] = useState(false);//
    const [checkedX, setCheckedX] = useState(false);
    // const p = useSelectedPage();   

    const [page, setPage] = useState('');

    const { id } = useParams();

    useEffect(() => {
        if (pages !== undefined || null) {
            console.log(' Page from Settings => ', pages);
            //setChecked(page[0].showContact);
            setPage(pages[0]);
        }
        console.log('pages =>', pages)

    }, [pages]);

    useEffect(() => {
        if (page !== undefined || null) {
            console.log('Checked', page);
            setCheckedD(page.showContactD);
            setCheckedX(page.showContactX);

        }

    }, [page]);

    const handleCheckD = (val) => {
        setCheckedD(val);
    };
    const handleCheckX = (val) => {
        setCheckedX(val);
    };

    useEffect(() => {//hook to change contact download boolean in firebase
        if (checkedD !== undefined || null) {
            if (checkedD !== page.showContactD && authUser) {
                fb.firestore.collection('linkPages').doc(id).update({
                    showContactD: checkedD
                })
            };
        };
    }, [checkedD]);

    useEffect(() => {
        if (checkedX !== undefined || null) {
            if (checkedX !== page.showContactX && authUser) {
                fb.firestore.collection('linkPages').doc(id).update({
                    showContactX: checkedX
                })
            };
        };
    }, [checkedX]);




    useEffect(() => {
        console.log('email => ', authUser?.email)
    }, [authUser]);

    const [passwordMsgOpen, setPasswordMsgOpen] = useState(false);

    const changePswd = () => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, authUser?.email)
            .then(() => {
                // Password reset email sent!
                // ..
                setPasswordMsgOpen(!passwordMsgOpen)
                console.log('email sent');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });
    };

    const ChangePswdMsg = () => {
        return (
            <div className={styles.popBackground}>
                <div className={styles.socialPop}>
                    <div className={styles.closeCont}> <Icon path={mdiClose} size={1} className={styles.closeSocialPop} onClick={() => setPasswordMsgOpen(false)} /></div>
                    <div className={styles.titleS}>Check your email for your password reset link</div>
                   
                </div>
            </div>
        )
    }




    return (
        <div className={styles.main}>
            <AuthedNav />
            <div className={styles.title}>Settings</div>

            <div className={styles.splitRow}>
                <div className={styles.txt}>Allow Contact Download on Public Page: </div>

                <ReactSwitch className={styles.switch} checked={checkedD} onChange={handleCheckD}
                    onColor="#9a81ff"
                    offColor="#e6e6e6"
                    checkedIcon={false}
                    uncheckedIcon={false}
                />
            </div>
            <div className={styles.splitRow}>
                <div className={styles.txt}>Allow Contact Exchange on Public Page: </div>

                <ReactSwitch className={styles.switch} checked={checkedX} onChange={handleCheckX}
                    onColor="#9a81ff"
                    offColor="#e6e6e6"
                    checkedIcon={false}
                    uncheckedIcon={false}
                />
            </div>
            <div className={styles.changePswd} onClick={changePswd}>
                Change Password
            </div>

            {passwordMsgOpen && <ChangePswdMsg/>}



        </div>
    )
} 