import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useParams } from 'react-router-dom';
import styles from './styles.module.css';
import { uuid } from "../../shared";
import { fb } from "../../shared/service";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, getBlob } from "firebase/storage";
import { useAuth, usePages } from "../../hooks";
import { doc, getDoc } from "firebase/firestore";
import { AuthedNav } from "../AuthedNav";
import { MdEdit } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { VCard } from '../VCardCreator';
import { Loading } from "../Loading/Loading";
import Icon from "@mdi/react";
import { mdiInformation, mdiInformationOutline } from "@mdi/js";
//import useAutoSizeBio from "./useAutoSizeBio";


export const CreatePageInfo = ({ page }) => {
    // onClick={() => history.push('/create-page')}

    const { id } = useParams();
    const location = useLocation();
    const [isEdit, setIsEdit] = useState(false);
    const { authUser } = useAuth();
    const pages = usePages(authUser?.uid);
    const newID = uuid();
    const [pageD, setPageD] = useState({});
    const history = useHistory();
    const [pageName, setPageName] = useState('');
    const [fullName, setFullName] = useState('');
    const [biography, setBiography] = useState('');
    const [pageId, setPageId] = useState('');
    const [error, setError] = useState('');
    const [taHeight, setTaHeight] = useState();
    const [isTeamPage, setIsTeamPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    // useAutoSizeBio(textAreanRef.current, value);
    function handleBio(event) {
        setBiography(event.target.value);
        const h = event.target.scrollHeight;
        const rows = event.target.rows;
        const rowHeight = 15;
        const trows = Math.ceil(h / rowHeight) - 1;
        if (trows) {
            setTaHeight(trows);
        }
    }

    useEffect(() => {
        /*  if (id && location.pathname.includes('edit-page-details')) {
              setIsEdit(true);
              console.log(isEdit);
              fb.firestore
                  .collection('linkPages')
                  .doc(id)
                  .get()
                  .then(res => {
                      const data = res.data();
                      if (data) {
                          setPageName(data.name);
                          //    setPageLinks(data.links);
                          //  setUserSocials(data.socials);
                          setFullName(data.userFullName);
                          setBiography(data.userBio);
                          setPageId(data.pageId);
                          setPageD(data);
                      } else {
                          console.error(404);
                      }
  
                  });
          } else {
              setIsEdit(false);
          }*/
        if (location) {
            if (location.pathname.includes('create-team-page')) {
                setIsTeamPage(true)
                setIsAdmin(true);
                console.log("location", location?.state)
                setFullName(location.state.teamName);
            }


        }


    }, [location]);

    const updateDetails = () => {
        if (authUser && pageName.length) {
            fb.firestore.collection('linkPages').doc(pageName).update({
                userFullName: fullName,
                userBio: biography

            })
                .then(() => {
                    console.log('updating page details');
                    // push('./Dashboard');
                    history.push('/');
                }).then(() => {
                    if (VCFChanged === true) {
                        updateVCF();
                    }
                });
        }
    }
    const sendRequest = async () => {
        setLoading(true);

        const docRef = doc(fb.firestore.collection('linkPages'), pageName);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setError(`The page name ${pageName} Has Already Been Taken :(`);
        } else {
            if (authUser && pageName && isTeamPage === true) {//upload main company page
                fb.firestore.collection('linkPages').doc(pageName.toLowerCase()).set({
                    userId: authUser.uid,
                    name: pageName.toLowerCase(),
                    pageId: newID,
                    userFullName: fullName,
                    headline: '',
                    userBio: biography,
                    links: [],
                    socials: [],
                    iconColor: '',
                    pageSelected: true,
                    showContactD: false,
                    showContactX: false,
                    companyId: location.state.companyId,
                    ...(isAdmin ? { isAdmin } : true)

                }).then(async () => {
                    fb?.firestore?.collection('analytics').doc(location.state.companyId).collection(newID).doc('visitors').set({
                        btnClicks: [],
                        pageVisits: []
                    }).then(async () => {
                        /* const privRef = doc(fb.firestore.collection('privateSlug'), id);
                         const privSnap = await getDoc(privRef)
                         if(!privSnap.exists()){*/
                        fb?.firestore?.collection('privateSlug').doc(id).set({
                            page: pageName
                        })
                        console.log("IT WORKED")
                        // }
                    }).catch((error) => {
                        console.error("Error writing document: ", error);

                    })
                        .then(() => {

                            history.push(`/edit-page-content/${pageName}`);
                        })
                });

            }
            else

                if (authUser && pageName) {
                    fb.firestore.collection('linkPages').doc(pageName.toLowerCase()).set({
                        userId: authUser.uid,
                        name: pageName.toLowerCase(),
                        pageId: newID,
                        userFullName: fullName,
                        headline: '',
                        userBio: biography,
                        links: [],
                        socials: [],
                        iconColor: '',
                        pageSelected: true,
                        showContactD: false,
                        showContactX: false,



                    }).then(async () => {
                        fb?.firestore?.collection('analytics').doc(authUser.uid).collection(newID).doc('visitors').set({
                            btnClicks: [],
                            pageVisits: []
                        }).then(async () => {
                            const privRef = doc(fb.firestore.collection('privateSlug'), id);
                            const privSnap = await getDoc(privRef)
                            if (!privSnap.exists()) {
                                fb?.firestore?.collection('privateSlug').doc(id).set({
                                    page: pageName
                                })
                                console.log("IT WORKED")
                                // 
                            }
                        }).catch((error) => {
                            console.error("Error writing document: ", error);

                        })
                            .then(() => {
                                history.push(`/edit-page-content/${pageName}`);
                            })
                    });
                }

        }
    };
    const [VCF, setVCF] = useState();
    const [VCFChanged, setVCFChanged] = useState(false);
    const updateVCF = (e) => {
        const storage = getStorage();
        fetch(VCF)
            .then(function (response) {
                return response.blob()
            })
            .then(function (VCF) {
                const storageRef = ref(storage, `contacts/${pageId}/${fullName}.vcf`);
                uploadBytes(storageRef, VCF).then((snapshot) => {
                    //  console.log('uploaded');
                    //console.log('VCF:' + VCF);
                });
            });
    }
    const handleVCFChange = e => {
        setVCF((URL.createObjectURL(e.target.files[0])));
        setVCFChanged(true);
    };
    const [vFormOpen, setVFormOpen] = useState(false);
    const openVForm = () => {
        setVFormOpen(!vFormOpen);
    }


    const contactFileInput = React.useRef(null);
    const addContactInput = e => {
        contactFileInput.current.click();
    };


    return (
        <div className={styles.main2}>
            <AuthedNav />
            <div className={styles.mainC}>
                {isAdmin && pages !== undefined ? <div className={styles.companyTitle}>{pages[0]?.company}</div>
                    : <div></div>
                }
                {isTeamPage &&
                    <div className={styles.h1}>Create your company's main yoits.me page
                    </div>}
                <div className={styles.infoInputsBox2}>
                    <div className={styles.pageNameTitle}>Page Name:</div>

                    {isEdit ? <div value={pageName} className={styles.pageName}>{pageName}</div>
                        :
                        <input
                            placeholder="/This will be the end of your URL"
                            value={pageName}
                            onChange={e => setPageName(e.target.value.toLowerCase().trim())}
                            className={styles.txtInput}
                        />}
                    <div className={styles.pageNameTitle}>{isTeamPage ? "Company Name:" : "Full Name:"}</div>
                    <input className={styles.txtInput}
                        value={fullName}
                        placeholder='Full Name'
                        onChange={e => setFullName(e.target.value)}
                        id="fullNameInput"
                    ></input>
                    <div className={styles.pageNameTitle}>Biography:</div>

                    <textarea
                        className={styles.inputBio}
                        value={biography}
                        placeholder={'Biography...'}
                        onChange={handleBio}
                        rows={taHeight}

                    //  ref={textAreanRef}
                    >

                    </textarea>
                </div>
                {error && <div className={styles.error}>{error} </div>}
                <button onClick={!isEdit ? sendRequest : updateDetails}
                    disabled={!pageName}
                    className={styles.submit}>

                    {!isEdit ? 'Create Page' : 'Update Page Details'}
                </button>
                {loading && <Loading />}
            </div>
        </div>
    );
};