
import styles from "./styles.module.css";
import yologoicon from './yoPadding.webp';


import { XChange } from "../XChange/XChange";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


export const NavBar = props => {
    const history = useHistory();
   
    const size = props.value;


    return (
        <div className={styles.main2}>
            <div className={styles.main}>
                <img src={yologoicon} className={styles.yologoicon}
                    onClick={() => history.push('/')} />


                <div className={styles.btnCont}>
                    {size.width >= 600 &&
                        <button className={styles.plainBtn}  onClick={() => history.push('/')}
                        >
                            Home
                        </button>
                    }
                    {size.width >= 600 &&
                        <button className={styles.plainBtn}  onClick={() => {window.open("https://www.yoitsme.app/shop")}}
                        >
                            Shop
                        </button>
                    }
                    {size.width >= 600 &&
                        <button className={styles.plainBtn}  onClick={() => history.push('/signup')}
                        >
                            Sign Up
                        </button>
                    }
                    <button className={styles.loginBtn}  onClick={() => history.push('/login')}
                        href="yoitsme.co/login"
                    >
                        Login
                    </button>

                </div>


            </div>

        </div>

    )
};

