import { async } from "@firebase/util";
import { getDoc, getDocs, collection, doc } from "firebase/firestore";
import { useEffect, useRef, useState, } from "react";
import { useParams } from "react-router-dom";
import { useAuth, usePages } from "../../hooks";
import { fb } from "../../shared/service";
import { AuthedNav } from "../AuthedNav";
import styles from './styles.module.css';
//import { Chart, registerables } from "chart.js";
import { useTabsList } from "@mui/base";


import { Line } from "react-chartjs-2";
import 'chart.js/auto'


export const Analytics = data => {

  const { authUser } = useAuth();
  const pages = usePages(authUser?.uid);
  const [page, setPage] = useState();
  const [analytics, setAnalytics] = useState();
  const [btnClicks, setBtnClicks] = useState();
  const [visits, setVisits] = useState();
  const [sortedVisits, setSortedVisits] = useState();
  const [groupByDay, setGroupByDay] = useState();
  const [sortedRegions, setSortedRegions] = useState();

  useEffect(() => {
    if (pages != null || undefined) {
      console.log('pages', pages)
      setPage(pages[0])
    }
  }, [!pages]);
  useEffect(() => {
    if (page != null || undefined) {
      console.log('page', page)
    }
  }, [!page]);

  useEffect(() => {
    if (page) {
      fb.firestore.collection('analytics').doc(page.userId).collection(page.pageId).doc('visitors').get()
        .then(res => {
          const data = res.data();
          if (data) {
            setAnalytics(data);
          } else {
            console.error(404);
          }
        })
    }
  }, [page]);

  useEffect(() => {
    if (analytics) {
      console.log('ANALYTICS:', analytics);
    }
  }, [analytics]);

  useEffect(() => {
    if (analytics) {
      const btnC = analytics.btnClicks;
      const pageV = analytics.pageVisits;
      // const { btnClicks, pageVisits } = analytics;
      setBtnClicks(btnC);
      setVisits(pageV);
    }

  }, [analytics]);


  useEffect(() => {
    if (visits != null || undefined) {
      console.log('visits', visits);
      // console.log('SortedRegions', sortedRegions);
      sort()
      timeSort();
      // TrafficChart();
    }
  }, [!visits])

  const sort = () => {
    const regionCounts = visits?.reduce((acc, visit) => {
      const region = visit?.region;
      acc[region] = (acc[region] || 0) + 1;
      // console.log('acc', acc)
      return acc;
    }, {});

    const sortedRegions = Object.keys(regionCounts).sort((a, b) => {
      return regionCounts[b] - regionCounts[a];
    }).map(region => {
      return { region, count: regionCounts[region] };
    });
    console.log('sorted:', sortedRegions)
    setSortedRegions(sortedRegions);
    return sortedRegions;
  }
  const timeSort = () => {
    setSortedVisits(visits.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp)));
  }

  useEffect(() => {
    if (sortedVisits) {
      console.log('sorted visits', sortedVisits);
      const groupByDay = sortedVisits.reduce((acc, visit) => {
        const date = new Date(visit.timeStamp).toDateString();
        // acc[date] = acc[date] ? acc[date] + 1 : 1;
        acc[date] = acc[date] || { date, count: 0 };
        acc[date].count++;
        return acc;
      }, {});
      // console.log(groupByDay);
      setGroupByDay(Object.values(groupByDay).reverse());


    }
  }, [sortedVisits]);


  const DailyTrafficChart = () => {
    const [selected7Days, setSelected7Days] = useState([]);
    const [labels, setLabels] = useState([]);
    const [dataV, setDataV] = useState([]);
    const [interval, setInterval] = useState(0);


    const moveToNextInterval = () => {
      if (interval + 0) {
        const firstDayIndex = groupByDay.findIndex(
          (item) => item.date === selected7Days[selected7Days.length - 1].date
        );
        const previousInterval = groupByDay.slice(firstDayIndex - 7, firstDayIndex);
        setSelected7Days(previousInterval.reverse());
        setInterval(interval - 1);
      }
    };

    const moveToPreviousInterval = () => {
      const lastDayIndex = groupByDay.findIndex((item) => item.date === selected7Days[0].date);
      const nextInterval = groupByDay.slice(lastDayIndex + 1, lastDayIndex + 8);
      setSelected7Days(nextInterval.reverse());
      setInterval(interval + 1)
    };

    useEffect(() => {
      if (groupByDay) {
        // const reversedData = groupByDay.slice().reverse();
        setSelected7Days(groupByDay.slice(0, 7).reverse());
      }
    }, [groupByDay]);

    useEffect(() => {
      if (selected7Days.length > 0) {
        setLabels(
          selected7Days.map((dataV) =>
            new Date(dataV.date).toLocaleDateString(undefined, {
              weekday: 'short',
              month: 'short',
              day: '2-digit'
            })
          )
        );
        setDataV(selected7Days.map((dataV) => dataV.count));
      }
    }, [selected7Days]);
    const options = {
      responsive: true,
      scales: {
        y: {
          labels: dataV,
          beginAtZero: false,
          ticks: {
            precision: 0,
            font: {
              size: 12,
              weight: 600,
              color: "#ffffff"
            }
          }
        },
        x: {
          type: 'category',
          labels: labels,
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            font: {
              size: 12,
              weight: 600,
              color: "#ffffff"
            }
          }
        }
      },
      plugins: {
        lengend: {
          display: false,
        }
      }
    };

    const chartData = {
      //  labels: labels,
      datasets: [
        {
          lineTension: .33,
          data: dataV,
          fill: true,
          pointStyle: 'circle',
          label: 'Page Visits',
          borderColor: '#9a81ff',
          backgroundColor: `#9a81ff59`
        },
      ]
    }

    return (
      <div className={styles.chartCont} >
        <button onClick={moveToPreviousInterval} className={styles.chartIterator}>Previous Week</button>
        <button onClick={moveToNextInterval} className={styles.chartIterator} disabled={interval === 0}>Next Week</button>
        <Line data={chartData} options={options} className={styles.chart} />

      </div>
    );
  }

  /*     {groupByDay?.map((l, index) => {
            return (
              <div key={index}>
                <div className={styles.row}>
                  <div>Date:</div>
                  <div key={index}>{l.date}</div>
                </div>
                <div className={styles.row}>
                  <div>Visits:</div>
                  <div key={index}>{l.count}</div>
                </div>
              </div>
            )
  
          })}*/

  return (
    <div className={styles.main}>
      <AuthedNav />

      <div className={styles.title}>Analytics</div>
      <div className={styles.mainC}>
        <div className={styles.topRow}>
          <div>
            <div className={styles.statCont}>
              <div>Total Page Views</div>
              <div>{visits?.length}</div>
            </div>
            <div className={styles.statCont}>
              <div>Total Clicks</div>
              <div>{btnClicks?.length}</div>
            </div>
          </div>

          <div>
            <div className={styles.topRegions}>Top Regions:</div>
            {sortedRegions?.map((l, index) => {
              return (
                <div key={index} className={styles.region}>
                  <div key={index}>{l.region}: </div>
                  <div key={index} className={styles.count}>{l.count} Visitors</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.chartTitle}>Chart</div>


        {DailyTrafficChart()}







      </div>

    </div>
  );

};