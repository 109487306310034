import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuth, usePages } from "../../hooks";
import { fb } from "../../shared/service";


export const GetCompanyInfo = () => {
    const {authUser} = useAuth();
    const pages = usePages(authUser?.uid);  
    const [companyId, setCompanyId] = useState();
    const [companyInfo, setCompanyInfo] = useState();
    const getCompanyInfo = async () => {
        const companyDocRef = doc(fb?.firestore, "companyInfo", pages[0]?.companyId);
        const res = await getDoc(companyDocRef);
        if (res.exists()) {
          //  console.log('COMPANY INFO', res.data())
            setCompanyInfo(res.data());
            return res.data();
        } else {
            console.log('doc doesnt exist')
        }
    }
      useEffect(() => {
        if(pages) {
            console.log('pages', pages);
            setCompanyId(pages[0]?.companyId);
            getCompanyInfo();
        }
      },[pages])
     //if(companyId && companyInfo !== null || undefined){
        return {
            companyId, 
            companyInfo
        };        
    // }


};