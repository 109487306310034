import { doc, getDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import { fb } from "../../shared/service";


export async function getPage(id) {
  //const history = useHistory();
  const docRef = doc(fb.firestore, 'privateSlug', id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const pageN = docSnap.data().page;
    console.log('PageN from function.js', pageN);
    return pageN;
  } else {
    return undefined;
  }
  ;




}


