import { mdiClose } from '@mdi/js';
import styles from './styles.module.css';
import React, { useState, useEffect } from "react";
import Icon from '@mdi/react';
import ReactPhoneInput from 'react-phone-input-mui';
import { TextField, withStyles } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import FileSaver from 'file-saver';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import './phone.css';
import { fb } from '../../shared/service';

export const VCard = props => {
    const { handleClose } = props;
    const [fbContact, setFbContact] = useState();
    const [currentVCF, setCurrentVCF] = useState({
        fName: '', lName: '', title: '', wEmail: '', email: '', mobile: '',
        workNumber: '', address: '', website: '', address: '', organization: ''
    });
    const [alreadyHasCInfo, setAlreadyHasCInfo] = useState(false);
    const [mobilePhoneNum, setMobilePhoneNum] = useState('');
    const [workPhoneNum, setWorkPhoneNum] = useState('');
    const [vFile, setVFile] = useState();
    const [vCreated, setVCreated] = useState(false);
    const [image, setImage] = useState(null);
    const [imgTxt, setImgTxt] = useState(null);

    useEffect(() => {
        setImage(props.value[3]);
        console.log('image', image);
        // setImgTxt( new Blob(image));
    }, [image]);

    const closeVcard = () => {
        handleClose();
    };


    useEffect(() => {
        if (props.value) {
            const contactRef = fb.firestore.collection('userContacts').doc(props.value[4]);
            contactRef.get().then((doc) => {
                if (doc.exists) {//update the users contact information 
                    console.log('doc data exists =>', doc?.data())
                    /* setCurrentVCF({
                         fName: doc.data().fName,
                         lName: doc.data().lName,
                         organization: doc.data().org,
                         email: doc.data().email,
                         wEmail: doc.data().wEmail,
                         address: doc.data().wAddress,
                         title: doc.data().wTitle,
                         website: doc.data().wSite,
                     });*/
                    setCurrentVCF((prevVCF) => ({
                        fName: doc.data().fName !== undefined ? doc.data().fName : prevVCF.fName,
                        lName: doc.data().lName !== undefined ? doc.data().lName : prevVCF.lName,
                        organization: doc.data().org !== undefined ? doc.data().org : prevVCF.organization,
                        email: doc.data().email !== undefined ? doc.data().email : prevVCF.email,
                        wEmail: doc.data().wEmail !== undefined ? doc.data().wEmail : prevVCF.wEmail,
                        address: doc.data().wAddress !== undefined ? doc.data().wAddress : prevVCF.address,
                        title: doc.data().wTitle !== undefined ? doc.data().wTitle : prevVCF.title,
                        website: doc.data().wSite !== undefined ? doc.data().wSite : prevVCF.website,
                    }));
                    setMobilePhoneNum((prevMobilePhoneNum) =>
                        doc.data().mPhone !== undefined ? doc.data().mPhone : prevMobilePhoneNum
                    );
                    setWorkPhoneNum((prevWorkPhoneNum) =>
                        doc.data().wPhone !== undefined ? doc.data().wPhone : prevWorkPhoneNum
                    );
                    setAlreadyHasCInfo(true);
                }
            })
        }
    }, [props]);

    useEffect(() => {
        if (fbContact) {
            console.log("fbContact => ", fbContact)
        }
    }, [fbContact]);

    const handleSubmit = (e) => {
        if (alreadyHasCInfo === true) {//update the users contact information 
            fb.firestore.collection('userContacts')
                .doc(props.value[4])
                .update(({
                    ...(currentVCF.fName !== "" && { fName: currentVCF.fName }),
                    ...(currentVCF.lName !== "" && { lName: currentVCF.lName }),
                    ...(currentVCF.organization !== "" && { org: currentVCF.organization }),
                    ...(currentVCF.email !== "" && { email: currentVCF.email }),
                    ...(currentVCF.wEmail !== "" && { wEmail: currentVCF.wEmail }),
                    ...(mobilePhoneNum !== "" && { mPhone: mobilePhoneNum }),
                    ...(workPhoneNum !== "" && { wPhone: workPhoneNum }),
                    ...(currentVCF.address !== "" && { wAddress: currentVCF.address }),
                    ...(currentVCF.title !== "" && { wTitle: currentVCF.title }),
                    ...(currentVCF.website !== "" && { wSite: currentVCF.website }),
                    //image
                }))
        } else {//create users document for their contact information
            console.log('doc doesnt exisit');
            fb.firestore.collection('userContacts')
                .doc(props.value[4])
                .set(({
                    ...(currentVCF.fName !== "" && { fName: currentVCF.fName }),
                    ...(currentVCF.lName !== "" && { lName: currentVCF.lName }),
                    ...(currentVCF.organization !== "" && { org: currentVCF.organization }),
                    ...(currentVCF.email !== "" && { email: currentVCF.email }),
                    ...(currentVCF.wEmail !== "" && { wEmail: currentVCF.wEmail }),
                    ...(mobilePhoneNum !== "" && { mPhone: mobilePhoneNum }),
                    ...(workPhoneNum !== "" && { wPhone: workPhoneNum }),
                    ...(currentVCF.address !== "" && { wAddress: currentVCF.address }),
                    ...(currentVCF.title !== "" && { wTitle: currentVCF.title }),
                    ...(currentVCF.website !== "" && { wSite: currentVCF.website }),
                    //image
                }));

        }
        closeVcard();
    }



    useEffect(() => {
        if (vCreated === true && vFile !== undefined || null) {
            updateVCF();
        }
    }, [vCreated]);

    const updateVCF = (e) => {////////////////////////////
        const storage = getStorage();
        const storageRef = ref(storage, `contacts/${props.value[4]}/${props.value[1]}/${props.value[2]}.vcf`);
        console.log('vFile right b4 fetch in uploadVCF()', vFile);
        const metaData = {
            contentType: 'text/vcard',
        };
        uploadBytes(storageRef, vFile, metaData).then((snapshot) => {
            console.log('Uploaded blob');
            props.handleClose();
        }).catch((error) => {
            console.log(error);
        });
    }
    useEffect(() => {
        console.log('mobile #:' + mobilePhoneNum);

    }, [mobilePhoneNum]);

    useEffect(() => {
        console.log('work #:' + workPhoneNum);
    }, [workPhoneNum]);

    return (
        <div className={styles.popBackground}>
            <div className={styles.box}>
                <div className={styles.closeCont} onClick={props.handleClose}>
                    <Icon path={mdiClose} size={1} className={styles.closeSocialPop} />
                </div>

                <div className={styles.titleS} >Create Contact Vcard:</div>

                <form className={styles.form}>
                    <div className={styles.numTitle}>First Name:</div>
                    <input
                        className={styles.input}
                        id='firstName'
                        type="text"
                        maxLength="20"
                        placeholder='First Name'
                        value={currentVCF.fName}
                        onChange={e => setCurrentVCF({ ...currentVCF, fName: e.target.value })}
                    />
                    <div className={styles.numTitle}>Last Name:</div>
                    <input
                        className={styles.input}
                        id='lastName'
                        type="text"
                        maxLength="25"
                        placeholder='Last Name'
                        value={currentVCF.lName}
                        onChange={e => setCurrentVCF({ ...currentVCF, lName: e.target.value })}
                    />


                    <div className={styles.numTitle}>Mobile Phone:</div>

                    <PhoneInput
                        defaultCountry={'us'}
                        country={'us'}
                        specialLabel={""}
                        className={styles.PhoneInput}
                        value={mobilePhoneNum}
                        onChange={setMobilePhoneNum}
                        component={TextField}
                        inputExtraProps={{
                            margin: 'normal',
                            autoComplete: 'phone',
                            border: 'none',
                        }}

                    />

                    <div className={styles.numTitle}>Work Phone:</div>
                    <PhoneInput
                        defaultCountry={'us'}
                        country={'us'}
                        specialLabel={""}
                        className={styles.PhoneInput}
                        value={workPhoneNum}
                        onChange={setWorkPhoneNum}
                        component={TextField}
                        inputExtraProps={{
                            margin: 'normal',
                            autoComplete: 'phone',
                            border: 'none',
                        }}

                    />

                    <div className={styles.numTitle}>Email:</div>

                    <input
                        className={styles.input}
                        id='work'
                        type="text"
                        maxLength="40"
                        placeholder='Email'
                        value={currentVCF.email}
                        onChange={e => setCurrentVCF({ ...currentVCF, email: e.target.value })}
                    />
                    <div className={styles.numTitle}>Work Email:</div>
                    <input
                        className={styles.input}
                        id='work'
                        type="text"
                        maxLength="40"
                        placeholder=' Work Email'
                        value={currentVCF.wEmail}
                        onChange={e => setCurrentVCF({ ...currentVCF, wEmail: e.target.value })}
                    />
                    <div className={styles.numTitle}>Organization:</div>
                    <input
                        className={styles.input}
                        id='work'
                        type="text"
                        maxLength="40"
                        placeholder='Organization'
                        value={currentVCF.organization}
                        onChange={e => setCurrentVCF({ ...currentVCF, organization: e.target.value })}
                    />
                    <div className={styles.numTitle}>Title:</div>
                    <input
                        className={styles.input}
                        id='work'
                        type="text"
                        maxLength="40"
                        placeholder='Title'
                        value={currentVCF.title}
                        onChange={e => setCurrentVCF({ ...currentVCF, title: e.target.value })}
                    />
                    <div className={styles.numTitle}>Website:</div>
                    <input
                        className={styles.input}
                        id='website'
                        type="text"
                        maxLength="60"
                        placeholder='Website'
                        value={currentVCF.website}
                        onChange={e => setCurrentVCF({ ...currentVCF, website: e.target.value })}
                    />
                    <div className={styles.numTitle}>Address:</div>
                    <input
                        className={styles.input}
                        id='address'
                        type="text"

                        placeholder='Address'
                        value={currentVCF.address}
                        onChange={e => setCurrentVCF({ ...currentVCF, address: e.target.value })}
                    />

                </form>

                <button className={styles.upload}
                    onClick={
                        // handleVCFSubmit
                        handleSubmit
                    }>
                    Upload Contact
                </button>

            </div>
        </div>

    );
}